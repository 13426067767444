/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import XDate from 'xdate'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import InfoList from '../../../components/Info-list/InfoList'
import { InfoListData } from '../../../components/Info-list/types'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextElement from '../../../components/text/Text'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { RootState } from '../../../_globals/state-store'
import { log } from '../../../_utilities/logging'
import { FormContainer } from '../filter/styled'
import { RawTicket, Ticket } from '../home/types'
import { TicketOverviewPageParams } from './types'
import { Environment } from '../../../_environments/environment'

const userAppSelector = (state: RootState) => state.user

/**
 * Ticket Overview Page
 * @returns {JSX.Element}
 */
const TicketOverview = (): JSX.Element => {
  const { plantId, ticketId } = useParams<TicketOverviewPageParams>()
  const userSelector = useAppSelector(userAppSelector)
  const navigation = useNavigate()
  const [ticket, setTicket] = useState<Ticket>()
  const [plantName, setPlantName] = useState<string>('')
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)
  // const [comments, setComments] = useState<string[]>([])
  const [comments, setComments] = useState<{ user: string; comment: string }[]>([])
  const [closeReasonId, setCloseReasonId] = useState<string | number | null>(null);


  /**
   * Page Title based on API status, ticket ID and ticket name
   */
  const pageTitle = useMemo(
    () => (isApiBusy ? 'Loading...' : `[${ticket?.id}] ${ticket?.nodeName}`),
    [isApiBusy, ticket?.id, ticket?.nodeName],
  )

  /**
   * Ticket Data for Info List
   */
  const ticketData = useMemo<InfoListData[]>(
    () => [
      {
        title: 'Plant Name',
        data: plantName,
        // data: ticket.plant_id,
        // data: ticket?.meter_id ? ticket.meter_id.toString() : 'N/A', // Updated to show plant_id
      },
      {
        title: 'Ticket ID',
        data: ticket?.id.toString(),
      },
      {
        title: 'Meter Tag',
        data: ticket?.nodeName,
      },
      {
        title: 'Meter Type',
        data: ticket?.meterType,
      },
      {
        title: 'Raised Date',
        data: ticket?.openDate
          ? new XDate(ticket?.openDate).toString('yyyy-MM-dd')
          : 'N/A',
      },
      {
        title: 'Assigned To',
        data: ticket?.assignee || 'Unassigned',
      },
      {
        title: 'Current Status',
        data: ticket?.status,
      },
      {
        title: 'Issue Type',
        data: ticket?.issueType,
      },
      // {
      //   title: 'Failure Reason',
      //   data: ticket?.closeReasonId ? ticket.closeReasonId.toString() : 'N/A',
      // },
    ],
    [
      plantName,
      ticket?.assignee,
      ticket?.id,
      ticket?.issueType,
      ticket?.meterType,
      ticket?.nodeName,
      ticket?.openDate,
      ticket?.status,
      ticket?.closeReasonId,
    ],
  )

  /**
   * Handle Page Ready Event
   */
  const handlePageReady = useCallback(() => {
    const plant = userSelector.plants.find(
      plantData => plantData.id.toString() === plantId,
    )
    setPlantName(plant?.name || '')
    // console.info("plantName",plantName)

    customFetch(
      'POST',
      `tickets/${plantId}`,
      {
        ticket_id: ticketId,
      },
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const rawTicket: RawTicket = result.data.anomalies[0]

          const newTicket: Ticket = {
            id: rawTicket.ticket_id.toString(),
            nodeName: rawTicket.node_name,
            meterType: rawTicket.type,
            openDate: rawTicket.raised_date,
            assignee: rawTicket.assigned_to,
            status: rawTicket.status,
            issueType: rawTicket.issue_type,
            lastDetected: rawTicket.update_date,
            severity: rawTicket.severity,
            // closeReasonId: rawTicket.close_reason_id, 
            closeReasonId: rawTicket.close_reason_id?.toString() || 'N/A', // Ensure closeReasonId is a string
          }

         console.info("plant Id",rawTicket.plant_id)

          // Create a map of plant IDs to names
    const plantNameMap = new Map<number, string>(
      Environment.whitePlantListNames.map(({ id, name }) => [id, name])
    )

       setPlantName(plantNameMap.get(rawTicket.plant_id))

       console.info("plant Name",plantName)



          setTicket(newTicket)
          setComments(rawTicket.comment_list) 
          setCloseReasonId(rawTicket.close_reason_id)
          // setCloseReasonId(
          //   typeof rawTicket.close_reason_id === 'string'
          //     ? parseInt(rawTicket.close_reason_id, 10)
          //     : rawTicket.close_reason_id
          // );

          console.info('comments', rawTicket.comment_list)
          
        } else {
          log(
            'LGYKBKT721WOAOYYV7W9',
            'Overview.tsx',
            'handlePageReady',
            'error',
            'Error loading ticket data',
            { result, plantId, ticketId },
          )

          setPageError('Error loading ticket data')
        }
      })
      .catch((error: Error) => {
        log(
          'V51ZS99LZGMRQGYLUOA9',
          'Overview.tsx',
          'handlePageReady',
          'error',
          'Error loading ticket data',
          { error, plantId, ticketId },
        )

        setPageError('Error loading ticket data')
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [plantId, ticketId, userSelector.plants])

  /**
   * Handle Add Comment Button Click
   */
  const handleAddComment = useCallback(() => {
    navigation(`/ticket/${ticketId}/add-comment`)
  }, [navigation, ticketId])

  // const currentStatus = ticketData.find(item => item.title === "Current Status").data;
  // console.info("ticket status", currentStatus);

  /**
   * Handle Add Reason Button Click
   */
  // const handleAddReason = useCallback(() => {
  //   navigation(`/ticket/${plantId}/${ticketId}/failure-reason`)
  // }, [navigation, plantId, ticketId])

  const handleAddReason = useCallback(() => {
    const currentStatus = ticketData.find(
      item => item.title === 'Current Status',
    )?.data
    const statusParam = currentStatus
      ? `status=${encodeURIComponent(currentStatus)}`
      : ''
    // navigation(`/ticket/${plantId}/${ticketId}/failure-reason?${statusParam}`)
    navigation(`/ticket/${plantId}/${ticketId}/failure-reason?${statusParam}`, {
      state: {
        closeReasonId: closeReasonId,
      },
    })
   
  }, [navigation, plantId, ticketId, ticketData, closeReasonId])




  /**
   * Handle Update Status Button Click
   */
  const handleUpdateStatus = useCallback(() => {
    navigation(`/ticket/${plantId}/${ticketId}/update-status`)
  }, [navigation, plantId, ticketId])

  return (
    <PageContainer
      allowNotifications={true}
      allowUnauthenticated={false}
      navbarPosition="top"
      menuType="ticket"
      pageError={pageError}
      pageTitle={pageTitle}
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      {isApiBusy ? (
        <Loading type="large" alignment="center" />
      ) : (
        <FormContainer>
          <InfoList title="" data={ticketData} maxWidth="300px" />
          <Spacer direction="vertical" amount="20px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Add Comment"
              theme="light"
              display="inline-block"
              callback={() => handleAddComment()}
            />
            <Spacer
              direction="horizontal"
              amount="20px"
              display="inline-block"
            />
            <Button
              text="Add Reason"
              theme="light"
              display="inline-block"
              callback={() => handleAddReason()}
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="50px" display="block" />
          <TextElement
            text="Recent Comments:"
            theme="h3"
            sizeOverride="100%"
            display="block"
          />

{comments.map((comment, index) => (
  <div key={index}>
    <TextElement
      text={`User: ${comment.user}`}
      theme="body"
      sizeOverride="100%"
      display="block"
    />
    <TextElement
      text={`Comment: ${comment.comment}`}
      theme="body"
      sizeOverride="100%"
      display="block"
    />
    <Spacer direction="vertical" amount="10px" display="block" />
  </div>
))}

          {console.info('comments state', comments)}
          {console.info('CLOSE REASON ID', closeReasonId)}

          <Spacer direction="vertical" amount="10px" display="block" />
          {/* <TextElement
            // text="Failure Reason:"
            text={`Failure Reason: ${ticket?.closeReasonId || 'N/A'}`}
            theme="h3"
            sizeOverride="100%"
            display="block"
          /> */}
          <Spacer direction="vertical" amount="20px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Update Status"
              theme="light"
              display="inline-block"
              callback={() => handleUpdateStatus()}
            />
          </AlignmentContainer>
          {console.info('ticket detail', ticketData)}
        </FormContainer>
      )}
    </PageContainer>
  )
}

export default TicketOverview
