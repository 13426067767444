// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../node_modules/react-toastify/dist/ReactToastify.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.azure-login-button {
  background-color: #0078d4;
  color: white;
  border: none;
  display: block;
  padding: 10px 20px;
  font-size: 100%;
  cursor: pointer;
  margin: 10px auto;
  width: 100%;
  max-width: 250px;
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.azure-login-button:hover {
  background-color: #0062a3;
}`, "",{"version":3,"sources":["webpack://./src/_globals/css/libraries.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;EACA,gCAAA;AAAF;AAEE;EACE,yBAAA;AAAJ","sourcesContent":["@import 'react-toastify/dist/ReactToastify.css';\n\n.azure-login-button {\n  background-color: #0078d4;\n  color: white;\n  border: none;\n  display: block;\n  padding: 10px 20px;\n  font-size: 100%;\n  cursor: pointer;\n  margin: 10px auto;\n  width: 100%;\n  max-width: 250px;\n  text-align: center;\n  font-weight: bold;\n  text-decoration: none;\n  transition: all 0.2s ease-in-out;\n\n  &:hover {\n    background-color: #0062a3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
