import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultTheme } from 'styled-components'

import { doesExist } from '../../_utilities/utils'
import InputContainer from '../input-container/InputContainer'
import InputLabel from '../input-label/InputLabel'
import TextElement from '../text/Text'
import AlignmentContainer from '../alignment-container/AlignmentContainer'
import { Input, TextArea } from './styled'
import { TextInputProps } from './types'

/**
 * A standardized version of <input />
 *
 * @param {TextInputProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <TextInput
 *  label="Please enter your address"
 *  colourMode="light"
 *  placeholder="Start typing your address"
 *  value={addressSearchQuery}
 * />
 * ```
 */
const TextInput = ({
  label,
  subLabel,
  initialValue,
  value,
  placeholder,
  type,
  isDisabled,
  colourMode,
  width,
  numberOfLines,
  display,
  offsetTop,
  offsetRight,
  offsetBottom,
  offsetLeft,
  offsetMode,
  elementId,
  onTextChange,
  onKeyUp,
  onBlur,
}: TextInputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(initialValue || '')

  const inputTheme = useMemo(() => {
    const theme: DefaultTheme = {
      background: colourMode === 'dark' ? '#000000' : '#ffffff',
      colour: colourMode === 'dark' ? '#ffffff' : '#000000',
      width: width || '100%',
    }

    return theme
  }, [colourMode, width])

  const sanitizedNumberOfLines = useMemo(
    () => numberOfLines || 0,
    [numberOfLines],
  )

  const sanitizedType = useMemo(() => {
    if (!doesExist(type)) {
      return 'text'
    }

    if (type === 'telephone') {
      return 'tel'
    }

    return type
  }, [type])

  const handleChange = useCallback(
    (
      changeEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const newValue = changeEvent?.target?.value ?? ''
      setInputValue(newValue)

      if (onTextChange) {
        onTextChange(newValue)
      }
    },
    [onTextChange],
  )

  const handleBlur = useCallback(
    (
      blurEventData: React.FocusEvent<
        HTMLInputElement | HTMLTextAreaElement,
        Element
      >,
    ) => {
      // blur info: https://youtu.be/SSbBvKaM6sk

      if (onBlur) {
        onBlur(blurEventData?.target?.value ?? '')
      }
    },
    [onBlur],
  )

  const handleKeyUp = useCallback(
    (
      keyUpEvent: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      if (onKeyUp) {
        onKeyUp(keyUpEvent?.currentTarget?.value ?? '')
      }
    },
    [onKeyUp],
  )

  useEffect(() => {
    if (value) {
      setInputValue(value)
    }
  }, [value])

  return (
    <InputContainer
      offsetTop={offsetTop}
      offsetRight={offsetRight}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      offsetMode={offsetMode}
      elementId={elementId}
      display={display}
      width={width}>
      {label !== '' ? (
        <AlignmentContainer align="left" display="block">
          <InputLabel
            label={label}
            colour={colourMode === 'dark' ? 'white' : 'black'}
          />
        </AlignmentContainer>
      ) : null}
      {subLabel && subLabel !== '' ? (
        <AlignmentContainer align="left" display="block">
          <TextElement
            text={subLabel}
            theme="small"
            alignment="left"
            colour="white"
            display="block"
          />
        </AlignmentContainer>
      ) : null}
      {sanitizedNumberOfLines > 1 ? (
        <TextArea
          theme={inputTheme}
          rows={sanitizedNumberOfLines}
          placeholder={placeholder || ''}
          onBlur={blurEventData => handleBlur(blurEventData)}
          onKeyUp={keyUpEvent => handleKeyUp(keyUpEvent)}
          onChange={changeEvent => handleChange(changeEvent)}
          value={inputValue}
          disabled={isDisabled === true}
        />
      ) : (
        <Input
          theme={inputTheme}
          placeholder={placeholder || ''}
          type={sanitizedType}
          onBlur={blurEventData => handleBlur(blurEventData)}
          onKeyUp={keyUpEvent => handleKeyUp(keyUpEvent)}
          onChange={changeEvent => handleChange(changeEvent)}
          value={inputValue}
          disabled={isDisabled === true}
        />
      )}
    </InputContainer>
  )
}

export default TextInput
