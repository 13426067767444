import XDate from 'xdate'

import { RawTicket, Ticket } from './types'

/**
 * Format the raw api data
 *
 * @param {RawTicket[]} data The raw api data
 * @returns {Ticket[]} The formatted data
 *
 * @example
 * ```ts
 * const formattedData = formatData(rawData)
 * ```
 */
const formatData = (data: RawTicket[]): Ticket[] =>
  data
    .map((item: RawTicket) => {
      // const openDate = new XDate(item.raised_date).toString('yyyy-MM-dd')
      const openDate = item.raised_date ? new XDate(item.raised_date).toString('yyyy-MM-dd') : 'N/A';
      const lastDetected = item.last_anomaly_detected ? new XDate(item.last_anomaly_detected).toString('yyyy-MM-dd') : 'N/A';

      return {
        id: item.ticket_id.toString(),
        issueType: item.issue_type,
        lastDetected,
        status: item.status,
        nodeName: item.node_name,
        openDate,
        severity: item.severity,
        assignee: item.assigned_to || 'Unassigned',
        meterType: item.type,
        closeReasonId: item.close_reason_id ? item.close_reason_id.toString() : 'N/A',  // Handle `close_reason_id`
      }
    })
    // .filter((item: Ticket) => item.id && item.nodeName)
    .filter((item: Ticket) => !!item.id && !!item.nodeName)

/**
 * Filter the data based on the query and filters
 *
 * @param {Ticket[]} data The formatted api data
 * @param {string} query The query to filter the data with
 * @param {string} assignee The assignee to filter the data with
 * @param {string} meterType The meter type to filter the data with
 * @param {string} ticketStatus The ticket status to filter the data with
 * @param {string} issueType The issue type to filter the data with
 * @returns {Ticket[]} The filtered data
 *
 * @example
 * ```ts
 * const filteredData = filterData(formattedData, query, assignee, meterType, ticketStatus, issueType)
 * ```
 */
const filterData = (
  data: Ticket[],
  query: string,
  assignee: string,
  meterType: string,
  ticketStatus: string,
  issueType: string,
): Ticket[] => {
  // const validTickets = data.filter((item: Ticket) => item.id && item.nodeName)
  const validTickets = data.filter((item: Ticket) => !!item.id && !!item.nodeName)

  if (
    query === '' &&
    assignee === 'all' &&
    meterType === 'all' &&
    ticketStatus === 'all' &&
    issueType === 'all'
  ) {
    return validTickets
  }

  return validTickets.filter((item: Ticket) => {
    const queryMatch =
      item.nodeName.toLowerCase().includes(query.toLowerCase()) ||
      item.id.toLowerCase().includes(query.toLowerCase())

    const assigneeMatch =
      assignee === 'all' ||
      (assignee === 'Unassigned' &&
        (item.assignee === '' || item.assignee === null)) ||
      item.assignee === assignee

    const meterTypeMatch =
      meterType === 'all' ||
      (meterType === 'Unassigned' &&
        (item.meterType === '' || item.meterType === null)) ||
      item.meterType === meterType

    const ticketStatusMatch =
      ticketStatus === 'all' || item.status === ticketStatus

    const issueTypeMatch = issueType === 'all' || item.issueType === issueType

    return (
      queryMatch &&
      assigneeMatch &&
      meterTypeMatch &&
      ticketStatusMatch &&
      issueTypeMatch
    )
  })
}

/**
 * Generate the filter text based on the filters applied
 *
 * @param {string} assignee The assignee to filter the data with
 * @param {string} meterType The meter type to filter the data with
 * @param {string} ticketStatus The ticket status to filter the data with
 * @param {string} issueType The issue type to filter the data with
 * @returns {string} The filter text
 *
 * @example
 * ```ts
 * const filterText = generateFilterText(assignee, meterType, ticketStatus, issueType)
 * ```
 */
const generateFilterText = (
  assignee: string,
  meterType: string,
  ticketStatus: string,
  issueType: string,
) => {
  const filters: string[] = []

  if (assignee !== 'all') {
    filters.push(`Assignee: ${assignee}`)
  }

  if (meterType !== 'all') {
    filters.push(`Meter Type: ${meterType}`)
  }

  if (ticketStatus !== 'all') {
    filters.push(`Ticket Status: ${ticketStatus}`)
  }

  if (issueType !== 'all') {
    filters.push(`Issue Type: ${issueType}`)
  }

  if (filters.length === 0) {
    return ''
  }

  return `Filtered by ${filters.join(', ')}`
}

export { formatData, filterData, generateFilterText }



