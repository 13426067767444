import { DropdownData } from '../../../components/dropdown-input/types'
import { Ticket } from '../home/types'

/**
 * Get the dropdown data from raw api data
 *
 * @param {Ticket[]} tickets The raw api data
 * @param {string} attribute The attribute to get the dropdown data for
 * @returns {DropdownData[]} The dropdown data
 *
 * @example
 * ```ts
 * const dropdownData = getDropdownData(rawData, 'status')
 * ```
 */
const getDropdownData = (
  tickets: Ticket[],
  attribute: string,
): DropdownData[] => {
  const uniqueValues = new Set(
    tickets.map(ticket => ticket[attribute] || 'Unassigned'),
  )

  return [...uniqueValues].map(value => ({
    id: value,
    displayValue: value,
  }))
}

export { getDropdownData }
