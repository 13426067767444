import styled from 'styled-components'

const FormContainer = styled.div`
  text-align: center;
  max-width: 400px;
  margin: auto;

  & select,
  & p,
  & ul,
  & h3 {
    width: 100%;
    max-width: 300px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
`

export { FormContainer }
