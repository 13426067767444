// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --theme-green: #2dce89;
  --theme-blue: #00aeef;
  --theme-indigo: #5e72e4;
  --theme-teal: #0eb1a3;
  --theme-yellow: #f1e14d;
  --theme-red: #f5365c;
  --theme-grey: #3f3f3f;
  --theme-dark-blue: #172b4d;
  --theme-background: #e9ecef;
}`, "",{"version":3,"sources":["webpack://./src/_globals/css/global-colours.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,qBAAA;EACA,uBAAA;EACA,qBAAA;EACA,uBAAA;EACA,oBAAA;EACA,qBAAA;EACA,0BAAA;EACA,2BAAA;AACF","sourcesContent":[":root {\n  --theme-green: #2dce89;\n  --theme-blue: #00aeef;\n  --theme-indigo: #5e72e4;\n  --theme-teal: #0eb1a3;\n  --theme-yellow: #f1e14d;\n  --theme-red: #f5365c;\n  --theme-grey: #3f3f3f;\n  --theme-dark-blue: #172b4d;\n  --theme-background: #e9ecef;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
