/* eslint-disable camelcase */
import React, { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextInput from '../../../components/text-input/TextInput'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { log } from '../../../_utilities/logging'
import { FormContainer } from './styled'
import { AddCommentPageParams } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * Add Comment Page
 * @returns {JSX.Element}
 */
const AddComment = (): JSX.Element => {
  const { id } = useParams<AddCommentPageParams>()
  const userSelector = useAppSelector(userAppSelector)
  const { showNotification } = useContext(NotificationContext)
  const [comment, setComment] = useState<string>('')

  /**
   * Event handler for when the save button is clicked
   */
  const handleSaveComment = useCallback(() => {
    if (comment === '') {
      showNotification({
        title: 'Comment cannot be empty',
        dismissAfter: 1500,
        type: 'error',
      })

      return null
    }

    customFetch(
      'POST',
      `tickets/${id}/actions/add-comment`,
      {
        comment: {
          comment: comment,
          created_by: userSelector.email
        }
      },
      'application/json',
      false,
      false,
    )
      .then(response => {
        console.info("comment", response)
        if (response.code === 200) {
          showNotification({
            title: 'Comment Added',
            dismissAfter: 1500,
            type: 'success',
          })
          
        // Clear the comment box
        setComment('');
        } else {
          log(
            '5T1XF2U8UY70SADVY70H',
            'AddComment.tsx',
            'handleSaveComment',
            'error',
            'Error adding comment',
            { response, comment, ticketId: id, email: userSelector.email },
          )

          showNotification({
            title: 'Error adding comment',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .catch((error: Error) => {
        log(
          'A6G9BBNITG2Y8HRBXYN0',
          'AddComment.tsx',
          'handleSaveComment',
          'error',
          'operations/addComment',
          { error, comment, ticketId: id, email: userSelector.email },
        )

        showNotification({
          title: 'Error adding comment',
          dismissAfter: 1500,
          type: 'error',
        })
      })
  }, [comment, showNotification, id, userSelector.email])

  return (
    <PageContainer
      height="100%"
      allowNotifications={true}
      allowUnauthenticated={false}
      navbarPosition="top"
      menuType="ticket"
      onlyShowBackButton={true}
      pageTitle="Add Comment"
      showNavBar={true}>
      <FormContainer>
        <TextInput
          label="Comment"
          numberOfLines={10}
          value={comment} // Ensure the value prop is set to reflect the state
          onTextChange={newValue => setComment(newValue)}
        />
        <Spacer direction="vertical" amount="50px" display="block" />
        <AlignmentContainer align="center" display="block">
          <Button
            text="Save"
            theme="main"
            size="large"
            callback={() => handleSaveComment()}
          />
        </AlignmentContainer>
      </FormContainer>
    </PageContainer>
  )
}

export default AddComment
