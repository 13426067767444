import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import PageError from '../../../components/page-error/PageError'
import Spacer from '../../../components/spacer/Spacer'
import TextInput from '../../../components/text-input/TextInput'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { ValidationError } from '../../../_types/validation'
import { log } from '../../../_utilities/logging'
import { generateId } from '../../../_utilities/utils'
import {
  convertToPayload,
  getMeterTypeDropdownData,
  getSeverityDropdownData,
  getStatusDropdownData,
  validate,
} from './helpers'
import { FormContainer } from './styled'
import {
  NewTicket,
  RawAssignee,
  RawDevice,
  RawIssueType,
  LocationState,
} from './types'
import { Environment } from '../../../_environments/environment'

const userAppSelector = (state: RootState) => state.user

/**
 * Add Ticket Page
 * @returns {JSX.Element}
 */
const AddTicket = (): JSX.Element => {
  const location = useLocation()
  const { plantId } = location.state as LocationState // fetch it from Home state

  console.info('FILTER PLANTID : ', plantId)

  const userSelector = useAppSelector(userAppSelector)
  const { showNotification } = useContext(NotificationContext)
  const [plantDropdownData, setPlantDropdownData] = useState<DropdownData[]>([])
  const [assigneeDropdownData, setAssigneeDropdownData] = useState<
    DropdownData[]
  >([])
  const [issueTypeDropdownData, setIssueTypeDropdownData] = useState<
    DropdownData[]
  >([])
  const [meterDropdownData, setMeterDropdownData] = useState<DropdownData[]>([])
  const [selectedPlant, setSelectedPlant] = useState<DropdownData>()
  const [selectedSeverity, setSelectedSeverity] = useState<string>('')
  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [selectedIssueType, setSelectedIssueType] = useState<string>('')
  const [selectedAssignee, setSelectedAssignee] = useState<string>('')
  const [selectedMeterType, setSelectedMeterType] = useState<DropdownData>()
  const [selectedMeter, setSelectedMeter] = useState<DropdownData>()
  const [description, setDescription] = useState<string>('')
  const [affectedCapacity, setAffectedCapacity] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [formErrors, setFormErrors] = useState<ValidationError[]>([])


  // const initialSelectedPlant = plantDropdownData.find(plant => plant.id === plantId);

  // const [selectedPlant, setSelectedPlant] = useState<DropdownData | undefined>(initialSelectedPlant);

  /**
   * Dynamically-updated complete ticket object
   */
  const newTicket = useMemo<NewTicket>(
    () => ({
      id: generateId(20),
      assignee: selectedAssignee,
      description,
      issueType: selectedIssueType,
      meterId: Number.parseInt(selectedMeter?.id, 10),
      meterType: selectedMeterType?.id,
      openDate: Math.round(Date.now() / 1000).toString(),
      plant_id: Number.parseInt(selectedPlant?.id, 10),
      // plant_id: selectedPlant
      //   ? Number.parseInt(selectedPlant.id, 10)
      //   : undefined,
      severity: selectedSeverity,
      status: selectedStatus,
      nodeName: selectedMeter?.displayValue,
      affectedCapacity,
      comment,
      // start_date_wt: Math.round(Date.now() / 1000).toString()
    }),
    [
      affectedCapacity,
      comment,
      description,
      selectedAssignee,
      selectedIssueType,
      selectedMeter?.displayValue,
      selectedMeter?.id,
      selectedMeterType?.id,
      selectedPlant?.id,
      selectedSeverity,
      selectedStatus,
    ],
  )

  /**
   * Formatted dropdown data for statuses
   */
  const statusDropdownData = useMemo<DropdownData[]>(
    () => getStatusDropdownData(),
    [],
  )

  /**
   * Formatted dropdown data for severities
   */
  const severityDropdownData = useMemo<DropdownData[]>(
    () => getSeverityDropdownData(),
    [],
  )

  /**
   * Formatted dropdown data for meter types
   */
  const meterTypeDropdownData = useMemo<DropdownData[]>(
    () => getMeterTypeDropdownData(),
    [],
  )


  const handleAssigneeChange = useCallback(
    (newValue: string) => {
      const selectedAssigneeData = assigneeDropdownData.find(
        assigneeData => assigneeData.id === newValue
      );
      setSelectedAssignee(selectedAssigneeData?.displayValue || '');
    },
    [assigneeDropdownData]
  );

  /**
   * Handle the meter type dropdown change
   *
   * @param {string} meterType The meter type
   */
  const handleMeterTypeChange = useCallback(
    (meterType: string) => {


        // Skip if selectedPlant is null or undefined
    if (!selectedPlant) return;
      const startDate = new Date()
      startDate.setHours(0, 0, 0, 1)
      const meterTypeData = meterTypeDropdownData.find(
        meterTypeDataPoint => meterTypeDataPoint.id === meterType,
      )

      setSelectedMeterType(meterTypeData)
      setSelectedMeter(null)
      setMeterDropdownData([])

      // const url = `data/device_status?r=${Math.round(
      //   startDate.getTime() / 1000,
      // )}`

      if (selectedPlant) {
        customFetch(
          'POST',
          'data/device_status',
          {
            plant_id: selectedPlant.id,
            meter_type: meterType,
          },
          'application/json',
          false,
          false,
        )
        .then(result => {
          // Log the raw result for debugging
          console.info('Raw result:', result);

          // Normalize the message for consistent comparison
          const message = result.message.replace(/^['"]+|['"]+$/g, '');

          console.info("message",message)

          if (result.code === 400) {
            if (message === 'BLOCK' || message === 'SCB' || message === 'Error') {
              // Show notification when specific messages are found
              showNotification({
                title: 'Not found',
                // message: `${result.message} is not available.`,
                dismissAfter: 1500,
                type: 'success',
              });
              console.info("Not found message:", result.message);
            } else {
              // Handle other cases where message is unexpected but status is 400
              showNotification({
                // title: ' not found',
                title: `${meterType} not found`,
                // message: `Bad request. ${result.message}`,
                dismissAfter: 1500,
                type: 'error',
              });
              console.error("Bad request:", result.message);
            }
          } else if (result.code === 200) {
            console.info("Meters fetched successfully");

            const meters: RawDevice[] = result.data.meters;

            setMeterDropdownData(
              meters.map(meterData => ({
                displayValue: meterData.meter_tag,
                id: meterData.meter_id.toString(),
              })),
            );
          } else {
            // Handle other response codes
            showNotification({
              title: `${meterType} not found`,
              // message: `Failed to fetch meters. ${result.message}`,
              dismissAfter: 1500,
              type: 'error',
            });
            console.error("Error fetching meters:", result.message);

            log(
              'COIRH0SME7838EEKNJUJ',
              'AddTicket.tsx',
              'handleMeterTypeChange',
              'error',
              'Error fetching meters',
              { result, plant_id: selectedPlant?.id, meterType },
            );
          }
        })
          .catch((error: Error) => {
            log(
              '2O4IP2Z7X211AUTTQQBZ',
              'AddTicket.tsx',
              'handleMeterTypeChange',
              'error',
              'Error fetching meters',
              { error, plant_id: selectedPlant?.id, meterType },
            )
          })
      }
    },
    
    [selectedPlant?.id, meterTypeDropdownData],
    // [selectedPlant, meterTypeDropdownData]
  )

  /**
   * Handle the plant dropdown change
   * @param {string} newValue The new plant value
   */
  const handlePlantChange = useCallback(
    (newValue: string) => {
      const plant = plantDropdownData.find(
        plantData => plantData.id === newValue,
      )

      console.info('Selected Plant ID newvalue:', newValue)
      console.info('Matching Plant Data:', plant)
      console.info('SELECTED Plant ID:', plant.id)

     

      if (plant) {

        setSelectedPlant(plant)
        setAssigneeDropdownData([])
        setSelectedAssignee(null)
        setIssueTypeDropdownData([])
        setSelectedIssueType(null)
     
        customFetch(
          'POST',
          'data/metadata',
          {
            plant_id: plant.id,
            p_name: 'manual',
            data_group: 'tickets',
          },
          'application/json',
          false,
          false,
        )
          .then(result => {
            if (result.code === 200) {
              console.info('custom SP', selectedPlant?.id)
              const rawIssueTypes: RawIssueType[] = result.data

              setIssueTypeDropdownData(
                rawIssueTypes.map(issueType => ({
                  displayValue: issueType.p_value,
                  id: issueType.id.toString(),
                })),
              )
             
            } else {
              log(
                'BAI8RFTN6G7LJ2X5Y733',
                'AddTicket.tsx',
                'handlePlantChange',
                'error',
                'Error fetching metadata',
                { result, plant_id: plant.id },
              )
            }
          })
          .catch((error: Error) => {
            log(
              '19K9DIRNTHHTSHQEIDKE',
              'AddTicket.tsx',
              'handlePlantChange',
              'error',
              'Error fetching metadata',
              { error, plant_id: plant.id },
            )
          })

        customFetch(
          'GET',
          `data/plant_user/${plant.id}`, // Append plant_id here
          null, // No need for a body in a GET request
          'application/json',
          false,
          false,
        )
          .then(result => {
            if (result.code === 200) {
              const rawAssignees: RawAssignee[] = result.data

              setAssigneeDropdownData(
                rawAssignees.map(assignee => ({
                  displayValue: assignee.email_id,
                  id: assignee.id.toString(),
                })),
              )

              // handleMeterTypeChange(meterTypeDropdownData[0].id)
              // Call handleMeterTypeChange with the default value (e.g., 'INV')
              // if (meterTypeDropdownData.length > 0) {
              //   handleMeterTypeChange(meterTypeDropdownData[0].id);
              // }
            } else {
              log(
                'U0WUM0SK8HKOXZN242S7',
                'AddTicket.tsx',
                'handlePlantChange',
                'error',
                'Error fetching plantUsersList',
                { result, plant_id: plant.id },
              )
            }
          })
          .catch((error: Error) => {
            log(
              '6W9LL9XLMCIHUZBH6JMV',
              'AddTicket.tsx',
              'handlePlantChange',
              'error',
              'Error fetching plantUsersList',
              { error, plant_id: plant.id },
            )
          })
      }
    },
    [handleMeterTypeChange, meterTypeDropdownData, plantDropdownData],
    // [meterTypeDropdownData, plantDropdownData]
  )

  /**
   * Handle the meter dropdown change
   * @param {string} newValue The new meter value
   */
  const handleMeterChange = useCallback(
    (newValue: string) => {
      const newMeter = meterDropdownData.find(
        meterData => meterData.id === newValue,
      )

      setSelectedMeter(newMeter)
    },
    [meterDropdownData],
  )

  useEffect(() => {
    console.info("use selector plants",userSelector.plants)
    if(selectedPlant) {
      console.info("selectedPlant useEffect",selectedPlant)
      handlePlantChange(selectedPlant.id.toString())
    }  else if (plantDropdownData.length > 0) {
        const firstPlant = userSelector.plants[0]
        handlePlantChange(firstPlant.id.toString())
      }
  }, [
    // handleMeterTypeChange,
    // handlePlantChange,
    // meterTypeDropdownData,
    plantDropdownData,
    // userSelector.plants,
    selectedPlant
  ])

  useEffect(() => {
   if (meterTypeDropdownData.length > 0) {
      handleMeterTypeChange(meterTypeDropdownData[0].id);
    }
    console.info("meterTypeDropdownData",meterTypeDropdownData)
  }, [selectedPlant])

  console.info('selected plant', selectedPlant)
  console.info('plantdropdown ', plantDropdownData)
  /**
   * Handle the page ready event
   */
  const handlePageReady = useCallback(() => {
    // Create a map of plant IDs to names
    const plantNameMap = new Map<number, string>(
      Environment.whitePlantListNames.map(({ id, name }) => [id, name])
    )
    setPlantDropdownData(() =>
      userSelector.plants
        .map(plant => ({
          // displayValue: plant.name,
          displayValue: plantNameMap.get(plant.id) || 'Unknown',
          id: plant.id.toString(),
        }))
        // .sort((a, b) => {
        //   if (a.displayValue < b.displayValue) {
        //     return -1
        //   }
        //   if (a.displayValue > b.displayValue) {
        //     return 1
        //   }
        //   return 0
        // }),
    )
    console.info('Plant Dropdown Data:', userSelector.plants)
  }, [userSelector.plants])

  /**
   * Handle the revalidate callback
   */
  const handleValidate = useCallback(() => {
    const errors = validate(newTicket)

    setFormErrors(errors)
  }, [newTicket])

  /**
   * Handle the save ticket callback
   */
  const handleSaveTicket = useCallback(() => {
    setFormErrors([])
    const errors = validate(newTicket)
    if (errors.length > 0) {
      setFormErrors(errors)
      showNotification({
        title: 'Please fix the form errors',
        type: 'error',
        dismissAfter: 3000,
      })

      return null
    }

    // const payload = convertToPayload(newTicket, userSelector.email)
    const payload = convertToPayload(newTicket, userSelector.email)
    // eslint-disable-next-line no-restricted-syntax
    console.log(payload)

    customFetch(
      'POST',
      'tickets/create_manual_ticket',
      payload,
      // {
      //   payload,
      //   start_date_wt:Math.round(Date.now() / 1000).toString()
      // },
      'application/json',
      false,
      false,
    )
      .then(response => {
        // eslint-disable-next-line no-restricted-syntax
        console.log(response)
        if (response.code === 200) {
          showNotification({
            title: 'Ticket Added',
            dismissAfter: 1500,
            type: 'success',
          })
        } else {
          log(
            '5T1XF2U8UY70SADVY70H',
            'AddTicket.tsx',
            'handleSaveTicket',
            'error',
            'Error adding ticket',
            // { response, comment, ticketId: id, email: userSelector.email },
          )

          showNotification({
            title: 'Error adding ticket',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .catch((error: Error) => {
        // eslint-disable-next-line no-restricted-syntax
        console.log(error)
      })
  }, [newTicket, showNotification, userSelector?.email])


  // Handle the issue type dropdown change
  const handleIssueTypeChange = useCallback(
    (newValue: string) => {
      const selectedIssue = issueTypeDropdownData.find(
        issueTypeData => issueTypeData.id === newValue,
      )
      setSelectedIssueType(selectedIssue?.displayValue || '')
    },
    [issueTypeDropdownData],
  )
 
  {
    console.info('Addticket plant dropdown', plantDropdownData)
  }

  return (
    <PageContainer
      height="100%"
      allowNotifications={true}
      allowUnauthenticated={false}
      navbarPosition="top"
      menuType="ticket"
      onlyShowBackButton={true}
      pageTitle="Add Ticket"
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      <FormContainer>
        <DropdownInput
          label="Plant"
          elementId="plant_id"
          data={plantDropdownData}
          onChange={newValue => handlePlantChange(newValue)}
        />
        {assigneeDropdownData.length === 0 ||
        issueTypeDropdownData.length === 0 ? (
          <Loading type="wide" alignment="center" />
        ) : (
          <>
            <Spacer direction="vertical" amount="10px" display="block" />
              <DropdownInput
              label="Equipment Type"
              elementId="meterType"
              data={meterTypeDropdownData}
              onChange={newValue => handleMeterTypeChange(newValue)}
            />
            {meterDropdownData.length === 0 ? (
              <Loading type="wide" alignment="center" />
            ) : (
              <>
                <Spacer direction="vertical" amount="10px" display="block" />
                <DropdownInput
                  label="Equipment"
                  elementId="meterId"
                  unselectedDisplayValue="Select a Meter"
                  data={meterDropdownData}
                  onChange={newValue => handleMeterChange(newValue)}
                />
                <Spacer direction="vertical" amount="10px" display="block" />
                <DropdownInput
                  label="Assigned To"
                  elementId="assignee"
                  unselectedDisplayValue="Select an Assignee"
                  data={assigneeDropdownData}
                  onChange={handleAssigneeChange}
                  // onChange={newValue => setSelectedAssignee(newValue)}
                />
                {console.info("ASSIGNEE : ", selectedAssignee)}
                {console.info("ASSIGNEE dropdown : ", assigneeDropdownData)}
                <Spacer direction="vertical" amount="10px" display="block" />
                <DropdownInput
                  label="Issue Type"
                  elementId="issueType"
                  unselectedDisplayValue="Select an Issue Type"
                  data={issueTypeDropdownData}
                  // onChange={newValue => setSelectedIssueType(newValue)}
                  onChange={newValue => handleIssueTypeChange(newValue)}
                />
                {console.info('issue type: ', issueTypeDropdownData)}
                {console.info('selected issue type: ', selectedIssueType)}
                <Spacer direction="vertical" amount="10px" display="block" />
                <DropdownInput
                  label="Severity"
                  elementId="severity"
                  unselectedDisplayValue="Select a Severity"
                  data={severityDropdownData}
                  onChange={newValue => setSelectedSeverity(newValue)}
                />
                <Spacer direction="vertical" amount="10px" display="block" />
                <DropdownInput
                  label="Status"
                  elementId="status"
                  unselectedDisplayValue="Select a Status"
                  data={statusDropdownData}
                  onChange={newValue => setSelectedStatus(newValue)}
                />
                <Spacer direction="vertical" amount="10px" display="block" />
                <TextInput
                  label="Affected Capacity"
                  elementId="affectedCapacity"
                  onTextChange={newValue => setAffectedCapacity(newValue)}
                />
                <Spacer direction="vertical" amount="10px" display="block" />
                <TextInput
                  label="Description"
                  elementId="description"
                  numberOfLines={5}
                  onTextChange={newValue => setDescription(newValue)}
                />
                <Spacer direction="vertical" amount="10px" display="block" />
                <TextInput
                  label="Comment"
                  elementId="comment"
                  numberOfLines={5}
                  onTextChange={newValue => setComment(newValue)}
                />
                <Spacer direction="vertical" amount="20px" display="block" />
                <PageError
                  data={formErrors.map(error => ({
                    message: error.message,
                    elementId: error.elementId ?? '',
                  }))}
                  revalidateCallback={() => handleValidate()}
                />
                <AlignmentContainer align="center" display="block">
                  <Button
                    text="Save"
                    theme="main"
                    size="large"
                    callback={() => handleSaveTicket()}
                  />
                </AlignmentContainer>
              </>
            )}
          </>
        )}
      </FormContainer>
    </PageContainer>
    
  )
}
export default AddTicket