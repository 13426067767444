import styled from 'styled-components'

const TicketContainer = styled.div`
  background-color: white;
  padding: 10px 10px 20px 10px;
  margin: 20px auto;
  max-width: 400px;
`

export { TicketContainer }
