import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { DefaultTheme } from 'styled-components'
import { useLocation, useNavigate } from 'react-router'

import BackIconFile from '../../../../_assets/icons/arrow-left-solid.svg'
import PortfolioIcon from '../../../../_assets/icons/grid-solid.svg'
import PortfolioIconSelected from '../../../../_assets/icons/grid-solid-selected.svg'
import PlantIcon from '../../../../_assets/icons/industry-windows-solid.svg'
import PlantIconSelected from '../../../../_assets/icons/industry-windows-solid-selected.svg'
import TicketIcon from '../../../../_assets/icons/ballot-check-solid.svg'
import TicketIconSelected from '../../../../_assets/icons/ballot-check-solid-selected.svg'
import IrradianceIcon from '../../../../_assets/icons/solar-panel-solid.svg'
import IrradianceIconSelected from '../../../../_assets/icons/solar-panel-solid-selected.svg'
import StatusReportIcon from '../../../../_assets/icons/file-lines-solid.svg'
import StatusReportIconSelected from '../../../../_assets/icons/file-lines-solid-selected.svg'
import DocumentsIcon from '../../../../_assets/icons/folder-solid.svg'
import DocumentsIconSelected from '../../../../_assets/icons/folder-solid-selected.svg'

import { NavBarProps } from './types'
import {
  BackButton,
  BackHeader,
  BackIcon,
  NavContainer,
  NavContentContainer,
  PageTitle,
} from './styled'
import NavLink from './components/nav-link/NavLink'

/**
 * The navbar portion of the NavMenu
 * @see NavMenu
 *
 * @param {NavBarProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <NavBar
 *  pagePosition="bottom"
 *  hideCartButton={hideCartButton || false}
 *  onCartClick={() => handleCartClick()}
 * />
 * ```
 */
const NavBar = ({
  pagePosition,
  menu,
  onlyShowBackButton,
  pageTitle,
}: NavBarProps): JSX.Element => {
  const reactLocation = useLocation()
  const navigation = useNavigate()
  const [path, setPath] = useState('')

  const containerTheme = useMemo(() => {
    const theme: DefaultTheme = {
      borderTop: 'none',
      borderBottom: 'none',
      height: menu === 'main' ? '90px' : '116px',
    }

    if (pagePosition === 'top') {
      theme.borderBottom = `1px solid #d1d1d1`
    } else {
      theme.borderTop = `1px solid #d1d1d1`
    }

    if (onlyShowBackButton === true) {
      theme.height = '43px'
    }

    return theme
  }, [menu, onlyShowBackButton, pagePosition])

  const handleBackClick = useCallback(() => {
    if (menu === 'main') {
      navigation('/')
    } else if (menu === 'plant') {
      navigation('/plants')
    } else if (menu === 'ticket') {
      if (
        path.includes('/overview') ||
        path.includes('/new') ||
        path.includes('/filter')
      ) {
        navigation('/tickets')
      } else {
        navigation(-1)
      }
    } else {
      navigation(-1)
    }
  }, [menu, navigation, path])

  useEffect(() => {
    setPath(reactLocation.pathname.toLowerCase())
  }, [reactLocation.pathname])

  return (
    <NavContainer theme={containerTheme} id="main_navigation_bar">
      {menu !== 'main' || onlyShowBackButton === true ? (
        <BackHeader>
          <BackButton onClick={() => handleBackClick()}>
            <BackIcon src={BackIconFile} />
          </BackButton>
          <PageTitle>{pageTitle}</PageTitle>
        </BackHeader>
      ) : null}
      {!onlyShowBackButton ? (
        <NavContentContainer>
          {menu === 'main' ? (
            <>
              <NavLink
                icon={PortfolioIcon}
                selectedIcon={PortfolioIconSelected}
                pagePosition={pagePosition}
                title="Portfolio"
                url="/portfolio"
                size="large"
                isActive={path.includes('portfolio') || path === '/'}
              />
              <NavLink
                icon={PlantIcon}
                selectedIcon={PlantIconSelected}
                pagePosition={pagePosition}
                title="Plants"
                url="/plants"
                size="large"
                isActive={path.includes('plant')}
              />
              <NavLink
                icon={TicketIcon}
                selectedIcon={TicketIconSelected}
                pagePosition={pagePosition}
                title="Tickets"
                url="/tickets"
                size="large"
                isActive={path.includes('ticket')}
              />
            </>
          ) : null}
          {menu === 'plant' ? (
            <>
              <NavLink
                icon={PlantIcon}
                selectedIcon={PlantIconSelected}
                pagePosition={pagePosition}
                title="Overview"
                url={`/plant/${path.split('/')[2]}/overview`}
                size="small"
                isActive={path.includes('overview')}
              />
              <NavLink
                icon={IrradianceIcon}
                selectedIcon={IrradianceIconSelected}
                pagePosition={pagePosition}
                title="Irradiance"
                url={`/plant/${path.split('/')[2]}/irradiance`}
                size="small"
                isActive={path.includes('irradiance')}
              />
              <NavLink
                icon={StatusReportIcon}
                selectedIcon={StatusReportIconSelected}
                pagePosition={pagePosition}
                title="Status Report"
                url={`/plant/${path.split('/')[2]}/status-report`}
                size="small"
                isActive={path.includes('status')}
              />
            </>
          ) : null}
          {menu === 'ticket' ? (
            <>
              <NavLink
                icon={TicketIcon}
                selectedIcon={TicketIconSelected}
                pagePosition={pagePosition}
                title="Overview"
                url={`/ticket/${path.split('/')[2]}/${
                  path.split('/')[3]
                }/overview`}
                size="small"
                isActive={path.includes('overview')}
              />
              <NavLink
                icon={DocumentsIcon}
                selectedIcon={DocumentsIconSelected}
                pagePosition={pagePosition}
                title="Documents"
                url={`/ticket/${path.split('/')[2]}/${
                  path.split('/')[3]
                }/documents`}
                size="small"
                isActive={path.includes('document')}
              />
            </>
          ) : null}
        </NavContentContainer>
      ) : null}
    </NavContainer>
  )
}

export default NavBar
