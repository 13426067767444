import styled from 'styled-components'

const Input = styled.input`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colour};
  width: 96%;
  max-width: 300px;
  height: 40px;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
`
Input.defaultProps = {
  theme: {
    background: '#ffffff',
    colour: '#000000',
    width: 'calc(100% - 20px)',
  },
}

const TextArea = styled.textarea`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colour};
  width: 96%;
  max-width: 300px;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;
`
TextArea.defaultProps = {
  theme: {
    background: '#ffffff',
    colour: '#000000',
    width: 'calc(100% - 20px)',
  },
}

export { Input, TextArea }
