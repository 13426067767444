/* eslint-disable no-restricted-syntax */
import chalk from 'chalk'

import { getEnvironmentSettings } from './config'

/**
 * Log an event
 * Wrapper for console.log/console.error
 *
 * @param {string} errorId - The error ID
 * @param {string} file - The file, plus extension
 * @param {string} functionName - The function name
 * @param {('info' | 'error' | 'warn')} type - The type of log
 * @param {string} message - The message to log
 * @param {unknown} [data] - Any additional relevant data
 * @param {boolean} [formatData] - Format the data in a pretty way
 * @returns {void}
 *
 * ```ts
 * log('Product.tsx', 'loadData', 'error', 'Product failed to load!', errorData, true)
 * ```
 */
const log = (
  errorId: string,
  file: string,
  functionName: string,
  type: 'info' | 'error' | 'warn',
  message: string,
  data?: unknown,
  formatData?: boolean,
  // eslint-disable-next-line max-params
): void => {
  let canLog = false
  const environment = getEnvironmentSettings()

  if (type === 'info' && environment.consoleLogLevel >= 3) {
    canLog = true
  } else if (type === 'warn' && environment.consoleLogLevel >= 2) {
    canLog = true
  } else if (type === 'error' && environment.consoleLogLevel >= 1) {
    canLog = true
  }

  if (canLog) {
    console[type](chalk.red(`[(${errorId}) ${file}: ${functionName}]`))
    console[type](message)

    if (data) {
      if (formatData) {
        const dataString = JSON.stringify(data, null, 4)
        console[type](dataString)
      } else {
        const dataString = JSON.stringify(data)
        console[type](dataString)
      }
    }

    console[type](' ')
  }

  if (type === 'error') {
    let emailBody = '<h1>Quadrical Error</h1>'
    emailBody += '</br>'
    emailBody += `<p style="margin:10px;font-weight:bold;">Error ID: ${errorId}</p>`
    emailBody += `<p style="margin:10px;">File: ${file}</p>`
    emailBody += `<p style="margin:10px;">Function: ${functionName}</p>`
    emailBody += `<p style="margin:10px;">Message: ${message}</p>`
    emailBody += `<p style="margin:10px;">Data: ${JSON.stringify(data)}</p>`

    fetch(
      'https://northamerica-northeast1-northern-devs-launchpad-prod.cloudfunctions.net/Open-Open',
      {
        body: JSON.stringify({
          signature: 'Utility-SendEmail',
          subject: `[QUADRICAL] Error in ${file}: ${functionName}`,
          from: 'pat@northern-devs.ca',
          body: emailBody,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    )
  }
}

export { log }
