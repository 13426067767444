import styled from 'styled-components'

const Dropdown = styled.select`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colour};
  width: 96%;
  height: 40px;
  max-width: 300px;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
`
Dropdown.defaultProps = {
  theme: {
    background: '#ffffff',
    colour: '#000000',
    width: 'initial',
  },
}

const DropdownOption = styled.option``

export { Dropdown, DropdownOption }
