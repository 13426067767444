import { AppEnvironment } from './types'

export const Environment: AppEnvironment = {
  ENV_CODE: 'local',
  ENV_NAME: 'Local',
  consoleLogLevel: 3,
  serverUrl: 'https://backend-api.quadricalai.com',
  authenticationServerUrl: 'https://backend-api.quadricalai.com',
  azure: {
    authorizationUrl:
      'https://login.microsoftonline.com/a76451b9-2c35-4b2a-8165-d4582ee09146/oauth2/v2.0/authorize',
    clientId: 'f7e44ded-c6a6-45cb-b7c2-7a0dce5cef54',
    redirectUri: 'https://mobile.quadrical.ai/login-success',
    //  redirectUri: 'http://localhost:3000'
  },
  dataRefreshInterval: 60_000,
  whitePlantList: [
    770, 771, 900, 772, 901, 4006, 3011, 3012, 4102, 4103, 652, 653, 4104, 655,
    657, 2101, 601,
  ],
  whitePlantListNames: [
    { id: 770, name: 'Adani_UP1' },
    { id: 771, name: 'Adani_Pavagada_2' },
    { id: 900, name: 'AP2_1' },
    { id: 772, name: 'Adani_Telangana_DCR' },
    { id: 901, name: 'AP2_2' },
    { id: 4006, name: 'Huafu' },
    { id: 3011, name: 'Poc_Wind' },
    { id: 3012, name: 'Malkapur' },
    { id: 4102, name: 'PH02' },
    { id: 4103, name: 'PH03' },
    { id: 652, name: 'Edenton' },
    { id: 653, name: 'Soltage' },
    { id: 4104, name: 'PH04' },
    { id: 655, name: 'Whitehaven' },
    { id: 657, name: 'BF_RW' },
    { id: 2101, name: 'Willoughby' },
    { id: 601, name: 'NED' },
  ],
}
