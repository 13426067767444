import React, { useCallback, useEffect, useState } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import NavMenu from './components/nav-menu/NavMenu'
import TopOfPage from './components/top-of-page/TopOfPage'
import Login from './pages/login/Login'
import NotFound from './pages/not-found/NotFound'
import { useAppDispatch, useAppSelector } from './_globals/hooks'
import { RootState } from './_globals/state-store'
import PortfolioHome from './pages/portfolio/home/PortfolioHome'
import { themeSlice } from './_globals/theme/theme-slice'
import { getWindowHeight, getWindowWidth } from './_utilities/utils'
import { getLocalStorageItem, removeLocalStorageItem } from './_data/storage'
import { LocalUserCopy } from './_globals/user/types'
import { userSlice } from './_globals/user/user-slice'
import PlantsHome from './pages/plants/home/PlantsHome'
import PlantDetail from './pages/plants/details/PlantDetail'
import PlantOverview from './pages/plants/overview/PlantOverview'
import PlantIrradiance from './pages/plants/irradiance/PlantIrradiance'
import PlantStatusReport from './pages/plants/status-report/PlantStatusReport'
import LoginSuccess from './pages/login-success/LoginSuccess'
import TicketsHome from './pages/tickets/home/Home'
import TicketFilter from './pages/tickets/filter/Filter'
import AddTicket from './pages/tickets/add-ticket/AddTicket'
import TicketOverview from './pages/tickets/overview/Overview'
import AddComment from './pages/tickets/add-comment/AddComment'
import UpdateFailureReason from './pages/tickets/update-failure-reason/UpdateFailureReason'
import UpdateStatus from './pages/tickets/update-status/UpdateStatus'
import Documents from './pages/tickets/documents/Documents'

const themeAppSelector = (state: RootState) => state.theme
const userAppSelector = (state: RootState) => state.user
let authorizationTimer: NodeJS.Timeout

/**
 * App
 * @returns {JSX.Element}
 */
function App() {
  const dispatch = useAppDispatch()
  const themeSelector = useAppSelector(themeAppSelector)
  const userSelector = useAppSelector(userAppSelector)
  const [userId, setUserId] = useState<string>('')
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  /**
   * Event handler for when the window is resized
   */
  const handleWindowResize = useCallback(() => {
    dispatch(themeSlice.actions.setWindowWidth(getWindowWidth()))
    dispatch(themeSlice.actions.setWindowHeight(getWindowHeight()))
  }, [dispatch])

  /**
   * Helper function to check if the user is authenticated
   */
  const handleCheckAuth = useCallback(() => {
    getLocalStorageItem('user', null).then(rawUser => {
      if (!rawUser) {
        setIsAuthenticated(false)
        return false
      }

      const user: LocalUserCopy = JSON.parse(rawUser)
      if (user.id === '' || user.email === '') {
        setIsAuthenticated(false)
        return false
      }

      setIsAuthenticated(true)
    })
  }, [])

  useEffect(() => {
    // attempt to load the user from local storage
    getLocalStorageItem('user', null).then(rawUser => {
      if (rawUser) {
        const user: LocalUserCopy = JSON.parse(rawUser)

        if (user.id !== '' && user.lastSave && user.lastSave > 0) {
          const twoWeeks = 1_209_600_000

          if (Date.now() - user.lastSave < twoWeeks) {
            // the data is less than two weeks old, so we can use it
            dispatch(userSlice.actions.reinitializeUser(user))
            setIsAuthenticated(true)
          } else {
            // the data is more than two weeks old, so we need to clear it
            dispatch(userSlice.actions.clearUser())
          }
        } else {
          // the data is invalid, so we need to clear it
          removeLocalStorageItem('user')
          dispatch(userSlice.actions.clearUser())
        }
      }
    })
  }, [dispatch])

  useEffect(() => {
    // set the user id
    if (userSelector.id !== userId) {
      setUserId(userSelector.id)
      handleCheckAuth()
    }
  }, [handleCheckAuth, userSelector?.id, userId])

  useEffect(() => {
    // initialize the window size
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    // check the authentication status every 30 seconds
    clearInterval(authorizationTimer)
    authorizationTimer = setInterval(() => {
      handleCheckAuth()
    }, 30_000)

    return () => {
      // clean up the event listeners
      window.removeEventListener('resize', handleWindowResize)
      clearInterval(authorizationTimer)
    }
  }, [handleCheckAuth, handleWindowResize])

  return (
    <Router>
      <TopOfPage />
      <NavMenu
        hideNavMenu={themeSelector.showNavbar === false}
        pagePosition={themeSelector.navbarPosition}
        onlyShowBackButton={themeSelector.onlyShowBackButton}
        menuType={themeSelector.menuType}
        pageTitle={themeSelector.pageTitle}
        sticky={true}>
        {themeSelector.allowNotifications ? <ToastContainer /> : null}
        {isAuthenticated ? (
          <Routes>
            <Route path="/" element={<PortfolioHome />} />
            <Route path="/login" element={<Login />} />
            <Route path="/login-success" element={<LoginSuccess />} />

            <Route path="/portfolio" element={<PortfolioHome />} />

            <Route path="/plants" element={<PlantsHome />} />
            <Route path="/plant/:id/details" element={<PlantDetail />} />
            <Route path="/plant/:id/overview" element={<PlantOverview />} />
            <Route path="/plant/:id/irradiance" element={<PlantIrradiance />} />
            <Route
              path="/plant/:id/status-report"
              element={<PlantStatusReport />}
            />

            <Route path="/tickets" element={<TicketsHome />} />
            <Route path="/tickets/new" element={<AddTicket />} />
            <Route path="/ticket/:id/filter" element={<TicketFilter />} />
            <Route
              path="/ticket/:plantId/:ticketId/overview"
              element={<TicketOverview />}
            />
            <Route
              path="/ticket/:plantId/:ticketId/failure-reason"
              element={<UpdateFailureReason />}
            />
            <Route
              path="/ticket/:plantId/:ticketId/update-status"
              element={<UpdateStatus />}
            />
            <Route
              path="/ticket/:plantId/:ticketId/documents"
              element={<Documents />}
            />
            <Route path="/ticket/:id/add-comment" element={<AddComment />} />

            <Route path="/home" element={<Navigate to="/" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/login-success" element={<LoginSuccess />} />
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </NavMenu>
    </Router>
  )
}

export default App
