import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DefaultTheme } from 'styled-components'

import { log } from '../../_utilities/logging'
import { formatErrorObject } from '../../_utilities/utils'
import InputContainer from '../input-container/InputContainer'
import InputLabel from '../input-label/InputLabel'
import { Dropdown, DropdownOption } from './styled'
import { DropdownData, DropdownInputProps } from './types'

/**
 * Fancy dropdowns!
 *
 * @param {DropdownInputProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <DropdownInput
 *  data={currencyDropdownData}
 *  onChange={newValue => handleCurrencyChange(newValue)}
 *  colourMode="light"
 *  display="block"
 *  initialValue={selectedCurrency}
 *  label="Select your Currency"
 *  isDisabled={isApiBusy}
 * />
 * ```
 */
const DropdownInput = ({
  onChange,
  data,
  initialValue,
  isDisabled,
  elementId,
  placeholder,
  unselectedDisplayValue,
  label,
  display,
  offsetBottom,
  offsetLeft,
  offsetRight,
  offsetTop,
  offsetMode,
  colourMode,
  width,
}: DropdownInputProps): JSX.Element => {
  const [value, setValue] = useState<string>('')

  const inputTheme = useMemo(() => {
    const theme: DefaultTheme = {
      background: colourMode === 'dark' ? '#000000' : '#ffffff',
      colour: colourMode === 'dark' ? '#ffffff' : '#000000',
      width: width ?? 'initial',
    }

    return theme
  }, [colourMode, width])

  const handleChange = useCallback(
    (newValue: React.ChangeEvent<HTMLSelectElement>) => {
      try {
        // eslint-disable-next-line unicorn/prefer-spread
        const selectedOption = Array.from(newValue.target).find(
          (selectOption: HTMLOptionElement | HTMLOptGroupElement) => {
            if (selectOption instanceof HTMLOptionElement) {
              return selectOption.selected
            }

            return false
          },
        )

        if ((selectedOption as HTMLOptionElement).value === '') {
          setValue('')
          onChange('')

          return null
        }

        const selectedId = data.find(
          dataPoint =>
            dataPoint.id === (selectedOption as HTMLOptionElement).value,
        ).id

        setValue(selectedId)

        if (onChange) {
          onChange(selectedId)
        }
      } catch (error) {
        log(
          'U4F10544BWR8RW19WXJK',
          'DropdownInput.tsx',
          'handleChange',
          'error',
          'Failed to find the selected ID based on the dataset',
          formatErrorObject(error),
          true,
        )
        setValue('')
      }
    },
    [onChange, data],
  )

  const formattedData = useMemo(() => {
    const reformattedData: DropdownData[] = data.map(dataPoint => ({
      id: dataPoint.id,
      displayValue: dataPoint.displayValue,
    }))

    if (unselectedDisplayValue) {
      reformattedData.unshift({ id: '', displayValue: unselectedDisplayValue })
    }

    return reformattedData
  }, [data, unselectedDisplayValue])

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <InputContainer
      offsetTop={offsetTop}
      offsetRight={offsetRight}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      offsetMode={offsetMode}
      elementId={elementId}
      display={display}>
      {label ? (
        <InputLabel
          label={label}
          colour={colourMode === 'dark' ? 'white' : 'black'}
        />
      ) : null}
      <Dropdown
        className={colourMode}
        theme={inputTheme}
        onChange={newValue => handleChange(newValue)}
        disabled={isDisabled === true}
        value={value}
        placeholder={placeholder || ''}>
        {formattedData.map(dataPoint => (
          <DropdownOption key={dataPoint.id} value={dataPoint.id}>
            {dataPoint.displayValue}
          </DropdownOption>
        ))}
      </Dropdown>
    </InputContainer>
  )
}

export default DropdownInput
