/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfoTableData } from '../../../components/info-table/types'

const gatherTableData = (rawData: any[]): InfoTableData[][] => {
  let body: InfoTableData[][] = []

  if (rawData) {
    body = rawData.map((row: any) =>
      Object.keys(row).map(key => ({
        header: key,
        value: row[key],
      })),
    )
  }

  return body
}

export { gatherTableData }
