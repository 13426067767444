import appPackage from '../../package.json'
import { AppEnvironment } from '../_environments/types'
import {
  Environment as loadedEnvironment,
  Environment,
} from '../_environments/environment'
import { log } from './logging'
import { formatErrorObject } from './utils'

/**
 * Get configuration information about the webapp
 *
 * @returns {any}
 *
 *  ```ts
 * const appInfo = getAppInfo()
 * ```
 */
const getAppInfo = () => {
  let numberOfDependencies = -1

  try {
    const deps = Object.keys(appPackage.dependencies)
    numberOfDependencies = deps.length
  } catch (error) {
    log(
      'GFXCLXMECLHZDGROCB5I',
      'config.ts',
      'getAppInfo',
      'error',
      'Error fetching ',
      formatErrorObject(error),
      true,
    )
    numberOfDependencies = -1
  }

  return {
    appVersion: appPackage.version ?? 'unknown',
    appName: appPackage.name ?? 'unknown',
    environment: loadedEnvironment.ENV_CODE,
    numberOfDependencies,
  }
}

/**
 * Fetch the devices timezone
 *
 * @returns {string}
 *
 * ```ts
 * const timezone = getTimezone()
 * ```
 */
const getTimezone = () => {
  // eslint-disable-next-line new-cap
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  return timezone
}

/**
 * Get environment settings for the webapp
 *
 * @returns {AppEnvironment}
 *
 * ```ts
 * getEnvironmentSettings()
 * ```
 */
const getEnvironmentSettings = (): AppEnvironment =>
  Environment ?? {
    ENV_CODE: 'prod',
    ENV_NAME: 'Production',
    consoleLogLevel: 1,
    serverUrl: 'https://backend-api.quadricalai.com',
    authenticationServerUrl: 'https://backend-api.quadricalai.com',
    azure: {
      authorizationUrl:
        'https://login.microsoftonline.com/a76451b9-2c35-4b2a-8165-d4582ee09146/oauth2/v2.0/authorize',
      clientId: 'f7e44ded-c6a6-45cb-b7c2-7a0dce5cef54',
      redirectUri: 'https://mobile.quadrical.ai/login-success',
      // redirectUri: 'http://localhost:3000'
    },
    dataRefreshInterval: 60_000,
    whitePlantList: [
      770, 771, 900, 772, 901, 4006, 3011, 3012, 4102, 4103, 652, 653, 4104, 655,
      657, 2101, 601,
    ],
    whitePlantListNames: [
      { id: 770, name: 'Adani_UP1' },
      { id: 771, name: 'Adani_Pavagada_2' },
      { id: 900, name: 'AP2_1' },
      { id: 772, name: 'Adani_Telangana_DCR' },
      { id: 901, name: 'AP2_2' },
      { id: 4006, name: 'Huafu' },
      { id: 3011, name: 'Poc_Wind' },
      { id: 3012, name: 'Malkapur' },
      { id: 4102, name: 'PH02' },
      { id: 4103, name: 'PH03' },
      { id: 652, name: 'Edenton' },
      { id: 653, name: 'Soltage' },
      { id: 4104, name: 'PH04' },
      { id: 655, name: 'Whitehaven' },
      { id: 657, name: 'BF_RW' },
      { id: 2101, name: 'Willoughby' },
      { id: 601, name: 'NED' },
    ],
  }

/**
 * Date formats
 */
const dateFormats = {
  fullMonthDayYear: 'MMMM d, yyyy',
  numericDayMonthYear: 'dd-MM-yyyy',
}

export { getAppInfo, getTimezone, getEnvironmentSettings, dateFormats }
