import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { InfoTable } from '../../../components/info-table/InfoTable'
import { InfoTableData } from '../../../components/info-table/types'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import TextElement from '../../../components/text/Text'
import { customFetch } from '../../../_data/api'
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../_data/storage'
import { log } from '../../../_utilities/logging'
import { PossiblePlants } from '../home/types'
import { gatherTableData } from './helpers'
import { PlantDetailPageParams } from './types'

/**
 * Plant Detail Page
 * @returns {JSX.Element}
 */
const PlantDetail = (): JSX.Element => {
  const { id } = useParams<PlantDetailPageParams>()
  const navigation = useNavigate()
  const [pageError, setPageError] = useState<string>('')
  const [plantData, setPlantData] = useState<InfoTableData[]>([])
  const [omData, setOmData] = useState<InfoTableData[]>([])
  const [assetData, setAssetData] = useState<InfoTableData[]>([])
  const [otherData, setOtherData] = useState<InfoTableData[]>([])
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)

  /**
   * The plant id, based on the url parameter
   */
  const plantId = useMemo(() => Number.parseInt(id.split('-')[0], 10), [id])

  /**
   * The plant name, based on the url parameter
   */
  const plantName = useMemo(() => id.split('-')[1], [id])

  /**
   * Flag to determine if there is any data to show
   */
  const anyDetails = useMemo(
    () =>
      plantData.length > 0 ||
      omData.length > 0 ||
      assetData.length > 0 ||
      otherData.length > 0,
    [plantData, omData, assetData, otherData],
  )

  /**
   * Event handler for when the page is ready
   *
   * @param {string} newPlantId The selected plant id
   */
  const handlePageReady = useCallback(
    (newPlantId?: string) => {
      setIsApiBusy(true)

      const plant_id = newPlantId ?? plantId

      customFetch(
        'GET',
        `data/plant_meta/${plant_id}`,  // Fixed URL here
        null,
        // { },
        'application/json',
        false,
        false,
      )
        .then(result => {
          if (result.code === 200) {
            // setPlantData(
            //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
            //   gatherTableData((result.data)),
            //   // gatherTableData((result.data as any)?.data?.Plant ?? {}),
            // )
            // // eslint-disable-next-line @typescript-eslint/no-explicit-any
            // setOmData(gatherTableData((result.data as any)?.data['O&M'] ?? {}))
            // setAssetData(
            //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
            //   gatherTableData((result.data as any)?.data?.Assets ?? {}),
            // )
            // setOtherData(
            //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
            //   gatherTableData((result.data as any)?.data?.Other ?? {}),
            // )
            const data = result.data;

          setPlantData(gatherTableData(data.Plant ?? {}));
          setOmData(gatherTableData(data['O&M'] ?? {}));
          setAssetData(gatherTableData(data.Assets ?? {}));
          setOtherData(gatherTableData(data.Other ?? {}));
          } else {
            log(
              'MHO28BTVVSAAE75FORYS',
              'PlantDetail.tsx',
              'handlePageReady',
              'error',
              'Error Loading Plant Data',
              result,
            )

            setPageError('Error Loading Plant Data')
          }
        })
        .catch((error: Error) => {
          log(
            'WLI5OX4SEDS6JG1G67FM',
            'PlantDetail.tsx',
            'handlePageReady',
            'error',
            'Error in the plantRelatedData API call',
            error,
          )

          setPageError('Error Loading Plant Data')
        })
        .finally(() => {
          setIsApiBusy(false)
        })
    },
    [plantId],
  )

  /**
   * Event handler for swipe gestures
   *
   * @param {string} direction The direction of the swipe
   */
  const handleSwipe = useCallback(
    (direction: 'left' | 'right') => {
      getLocalStorageItem('possible-plants', null).then(rawPossiblePlants => {
        if (rawPossiblePlants) {
          const possiblePlants: PossiblePlants = JSON.parse(rawPossiblePlants)
          const currentPlantIndex = possiblePlants.plants.findIndex(
            currentPlant => currentPlant.id === plantId.toString(),
          )

          if (direction === 'left') {
            const nextPlantIndex =
              currentPlantIndex === possiblePlants.plants.length - 1
                ? 0
                : currentPlantIndex + 1

            possiblePlants.currentPlant = possiblePlants.plants[nextPlantIndex]
          } else {
            const nextPlantIndex =
              currentPlantIndex === 0
                ? possiblePlants.plants.length - 1
                : currentPlantIndex - 1

            possiblePlants.currentPlant = possiblePlants.plants[nextPlantIndex]
          }

          // Make sure the index is valid
          if (
            currentPlantIndex >= 0 &&
            currentPlantIndex < possiblePlants.plants.length
          ) {
            setLocalStorageItem(
              'possible-plants',
              JSON.stringify(possiblePlants),
            ).then(() => {
              const plant = possiblePlants.plants.find(
                plants => plants.id === possiblePlants.currentPlant.id,
              )

              if (plant) {
                navigation(`/plant/${plant.id}-${plant.name}/details`)
                handlePageReady(plant.id)
              }
            })
          }
        }
      })
    },
    [handlePageReady, navigation, plantId],
  )

  return (
    <PageContainer
      offsetTop="20px"
      offsetBottom="20px"
      offsetMode="padding"
      height="100%"
      allowNotifications={true}
      allowUnauthenticated={false}
      pageError={pageError}
      onlyShowBackButton={true}
      navbarPosition="top"
      menuType="plant"
      pageTitle={plantName}
      showNavBar={true}
      onSwipeLeft={() => handleSwipe('left')}
      onSwipeRight={() => handleSwipe('right')}
      onPageReady={() => handlePageReady()}>
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <>
          {anyDetails === false ? (
            <TextElement
              text="No additional data available"
              theme="paragraph"
              alignment="center"
              display="block"
            />
          ) : null}
          {plantData.length > 0 ? (
            <InfoTable title="Data" data={[plantData]} view="card" />
          ) : null}
          {omData.length > 0 ? (
            <InfoTable title="O&M" data={[omData]} view="card" />
          ) : null}
          {assetData.length > 0 ? (
            <InfoTable title="Assets" data={[assetData]} view="card" />
          ) : null}
          {otherData.length > 0 ? (
            <InfoTable title="Other" data={[otherData]} view="card" />
          ) : null}
        </>
      )}
    </PageContainer>
  )
}

export default PlantDetail
