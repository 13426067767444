import React from 'react'

import TextElement from '../text/Text'
import Loading from '../loading/Loading'
import { InfoTableProps } from './types'
import {
  Row,
  Header,
  Data,
  Container,
  HiddenInput,
  Cell,
  Table,
  TableContainer,
  TableHead,
} from './styled'

/**
 * Display a simple table of information
 *
 * @param {InfoTableProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 *  <InfoTable title="Order Information" data={orderData.information} />
 * ```
 */
const InfoTable = ({ title, data, view }: InfoTableProps): JSX.Element =>
  data && data.length > 0 ? (
    <Container>
      <TextElement text={title} theme="h1" alignment="center" display="block" />
      <TableContainer>
        <Table className={view === 'card' ? 'as-cards' : ''}>
          <TableHead>
            <Row>
              {data[0].map(cell => (
                <Header key={`header-${cell.header}`}>{cell.header}</Header>
              ))}
            </Row>
          </TableHead>
          {data.length > 0 && data[0].length > 0 ? (
            <tbody>
              {data.map((dataPoint, index) => (
                <Row key={`row-${dataPoint[0].header}-${index.toString()}`}>
                  {dataPoint.map(cell => (
                    <Cell key={`cell-${cell.header}`} data-label={cell.header}>
                      {cell.useHiddenInput === true ? (
                        <HiddenInput
                          value={cell.value}
                          type="text"
                          readOnly={true}
                        />
                      ) : (
                        <Data>{cell.value}</Data>
                      )}
                    </Cell>
                  ))}
                </Row>
              ))}
            </tbody>
          ) : (
            <Loading type="wide" />
          )}
        </Table>
      </TableContainer>
    </Container>
  ) : null

export { InfoTable }
