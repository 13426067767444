/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlantOverviewData } from './types'

/**
 * Get the measurement title from the entity code
 *
 * @param {string} entity The entity code
 * @returns {string} The measurement title
 *
 * @example
 * ```ts
 * const measurementTitle = getMeasurementTitleFromEntity('hz_ins')
 * ```
 */
const getMeasurementTitleFromEntity = (entity: string): string => {
  const sanitizedEntity = entity.toLowerCase()

  if (sanitizedEntity === 'today pr') {
    return 'PR TODAY'
  } else if (sanitizedEntity === 'hz_ins') {
    return 'GHI TODAY'
  } else if (sanitizedEntity === 'tilt_ins') {
    return 'GII TODAY'
  } else if (sanitizedEntity === 'today geneation') {
    return 'Energy TODAY'
  } else if (sanitizedEntity === 'current power') {
    return 'Power'
  }
}

/**
 * Format the api data
 *
 * @param {any} data The api data
 * @returns {PlantOverviewData} The formatted data
 *
 * @example
 * ```ts
 * const formattedData = formatData(rawData)
 * ```
 */
const formatData = (data: any): PlantOverviewData => {
  const percentageChange =
    ((data.series[0] - data.series[1]) / data.series[1]) * 100
  const upOrDown = percentageChange > 0 ? 'up' : 'down'

  let formattedChange = ''

  if (Number.isNaN(percentageChange)) {
    formattedChange = 'N/A'
  } else if (Number.isFinite(percentageChange) === false) {
    formattedChange = '+100%'
  } else {
    formattedChange = `${upOrDown === 'up' ? '+' : '-'}${Math.abs(
      percentageChange,
    ).toFixed(2)}%`
  }

  return {
    measurementTitle: getMeasurementTitleFromEntity(data.entity_name),
    attribute: data.property,
    unit: `(${data.unit})`,
    currentValue: data.series[0],
    previousValue: data.series[1],
    change: percentageChange.toFixed(2),
    upOrDown,
    formattedChange,
  }
}

export { formatData }
