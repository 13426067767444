import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import InfoList from '../../../components/Info-list/InfoList'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextInput from '../../../components/text-input/TextInput'
import TextElement from '../../../components/text/Text'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { log } from '../../../_utilities/logging'
import { filterData, formatData, generateFilterText } from './helpers'
import { TicketContainer } from './styled'
import { RawTicket, Ticket } from './types'
import { Environment } from '../../../_environments/environment'

const userAppSelector = (state: RootState) => state.user

/**
 * Tickets Home Page
 * @returns {JSX.Element}
 */
const TicketsHome = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const userSelector = useAppSelector(userAppSelector)
  // const [urlParameters] = useSearchParams()
  const [plantDropdownData, setPlantDropdownData] = useState<DropdownData[]>([])
  // const [selectedPlant, setSelectedPlant] = useState<DropdownData>()
  const [selectedPlant, setSelectedPlant] = useState<DropdownData | undefined>(undefined);

  const [tickets, setTickets] = useState<Ticket[]>([])
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)


   // Parse URL parameters
   const urlParameters = new URLSearchParams(location.search)

   // Get plantId from URL
   const plantIdFromUrl = urlParameters.get('plantId')
   // Extract only the numeric part using a regular expression
   const numericPlantId = plantIdFromUrl ? plantIdFromUrl.match(/\d+/)[0] : null;



  /**
   * Filtered Data based on search query input
   */
  const filteredData = useMemo(
    () =>
      filterData(
        tickets,
        searchQuery,
        urlParameters.get('assignee') || 'all',
        urlParameters.get('meterType') || 'all',
        urlParameters.get('status') || 'all',
        urlParameters.get('issueType') || 'all',
      ),
    [searchQuery, tickets, urlParameters],
  )

  /**
   * Generate the default filter text for the search bar based on the url parameters
   */
  const filterText = useMemo(
    () =>
      generateFilterText(
        urlParameters.get('assignee') || 'all',
        urlParameters.get('meterType') || 'all',
        urlParameters.get('status') || 'all',
        urlParameters.get('issueType') || 'all',
      ),
    [urlParameters],
  )

  /**
   * Fetch data from the API
   * @param {number} plantId Plant ID
   */
  const fetchData = useCallback(
    (plantId: number) => {
      if (isApiBusy) {
        showNotification({
          title: 'Loading, One Moment',
          dismissAfter: 1500,
          type: 'info',
        })

        return null
      }
      setIsApiBusy(true)
      const startDate = new Date()
      startDate.setHours(0, 0, 0, 1)
      const endDate = new Date()
      endDate.setHours(23, 55, 59, 0)
      // const endDate = '1723055159'
      // const startDate = '1722969000'
      const url = `tickets`
      console.info("plantIdFromUrl",plantIdFromUrl)
      console.log('Extracted Plant ID:', numericPlantId);
      customFetch(
        'POST',
        'tickets',
        {
          plant_id: plantId,
          start_date: Math.round(startDate.getTime() / 1000).toString(),
          end_date: Math.round(endDate.getTime() / 1000).toString(),
        },
        'application/json',
        false,
        false,
      )
        .then(result => {
          if (result.code === 200) {
            const rawTickets: RawTicket[] = result.data.anomalies
            console.info('rawTickets', rawTickets)
            const formattedTickets: Ticket[] = formatData(rawTickets)
            console.info('formattedTickets', formattedTickets)

            setTickets(formattedTickets)
          } else {
            log(
              '6LB5VAK32XUNJC6K40IR',
              'Home.tsx',
              'fetchData',
              'error',
              'Error Loading Portfolio Data',
              { result, url },
            )

            setPageError('Error Loading Portfolio Data')
            showNotification({
              title: 'Error Loading Portfolio Data',
              dismissAfter: 1500,
              type: 'error',
            })
          }
        })
        .catch((error: Error) => {
          log(
            'GXGBDJRH7U47L4TBVIU2',
            'Home.tsx',
            'fetchData',
            'error',
            'Error Loading Portfolio Data',
            { error, url },
          )

          setPageError('Error Loading Portfolio Data')
          showNotification({
            title: 'Error Loading Portfolio Data',
            dismissAfter: 1500,
            type: 'error',
          })
        })
        .finally(() => {
          setIsApiBusy(false)
        })
    },
    [isApiBusy, showNotification],
  )

  /**
   * Handle Page Ready Event
   */
  const handlePageReady = useCallback(() => {
    // setPlantDropdownData(() =>
    //   userSelector.plants
    //     // .filter(plant => plant.id === 770) // Exclude plant with ID 770
    //     .map(plant => ({
    //       // displayValue: plant.name,
    //       displayValue: plant.id.toString(),
    //       id: plant.id.toString(),
    //     }))
    //     // .sort((a, b) => {
    //     //   if (a.displayValue < b.displayValue) {
    //     //     return -1
    //     //   }
    //     //   if (a.displayValue > b.displayValue) {
    //     //     return 1
    //     //   }
    //     //   return 0
    //     // }),
    // )
    let plants = [...userSelector.plants]; // Create a copy of the plants array to avoid modifying the original array

    // Determine the plant to be used (either numericPlantId or the first plant)
    const selectedPlantId = numericPlantId
      ? numericPlantId.toString()
      : plants[0]?.id.toString();
  
    // Reorder the plants array to place the selected plant at the top
    const sortedPlants = plants.sort((a, b) => {
      if (a.id.toString() === selectedPlantId) return -1;
      if (b.id.toString() === selectedPlantId) return 1;
      return 0;
    });
  
    // Create a map of plant IDs to names
    const plantNameMap = new Map<number, string>(
      Environment.whitePlantListNames.map(({ id, name }) => [id, name])
    )
    // Set the dropdown data
    setPlantDropdownData(() =>
      sortedPlants.map(plant => ({
        // displayValue: plant.id.toString(),
        displayValue: plantNameMap.get(plant.id) || 'Unknown',
        id: plant.id.toString(),
      }))
      // .sort((a, b) => {
      //     if (a.displayValue < b.displayValue) {
      //       return -1
      //     }
      //     if (a.displayValue > b.displayValue) {
      //       return 1
      //     }
      //     return 0
      //   })
    );
    console.info("handlePageReady called")
      
    // const firstPlant = userSelector.plants[0]
    const plantToUse = numericPlantId ? numericPlantId : userSelector.plants[0].id
   console.info("plantToUse", plantToUse)
   

   if(numericPlantId) {
    console.info("numericPlantId")
    setSelectedPlant({
      displayValue: numericPlantId.toString(),
      id: numericPlantId.toString(),
    })
   } else if(!numericPlantId) {
    console.info("numericPlantId NOT")
     const firstPlant = userSelector.plants[0]
    setSelectedPlant({
      displayValue: firstPlant.id.toString(),
      id: firstPlant.id.toString(),
    })
   }
   
    // setSelectedPlant({
    //   // displayValue: firstPlant.name,
    //   // displayValue: firstPlant.id.toString(),
    //   // id: firstPlant.id.toString(),
    //   displayValue: plantToUse.toString(),
    //   id: plantToUse.toString(),
    // })

    // setSelectedPlant({displayValue: '901', id: '901'})
    

   console.info("SelectedPlant 2", selectedPlant)

    // fetchData(firstPlant.id)
    fetchData(Number(plantToUse))
  }, [fetchData, userSelector.plants, numericPlantId])

  /**
   * Handle Plant Change dropdown event
   * @param {string} plantId Plant ID
   */
  const handlePlantChange = useCallback(
    (plantId: string) => {
      const plant = plantDropdownData.find(
        plantData => plantData.id === plantId,
      )

      console.info("handlePlantChange called")
      if (plant) {
        setSelectedPlant(plant)
        fetchData(Number.parseInt(plant.id, 10))
        console.info("SelectedPlant 3", plant)
      }
  

    },
    [fetchData, plantDropdownData],
  )

  /**
   * Handle Add Ticket Click Event
   */
  const handleAddTicketClick = useCallback(() => {
    navigation('/tickets/new', {
      state: { plantId: selectedPlant?.id },
    })
  }, [navigation, selectedPlant])

  /**
   * Handle Modify Filter Click Event
   */
  const handleModifyFilterClick = useCallback(() => {
    let url = `/ticket/${selectedPlant?.id}/filter`
    const filters: string[] = []

    if (urlParameters.has('assignee')) {
      filters.push(`assignee=${urlParameters.get('assignee')}`)
    }

    if (urlParameters.has('meterType')) {
      filters.push(`meterType=${urlParameters.get('meterType')}`)
    }

    if (urlParameters.has('status')) {
      filters.push(`status=${urlParameters.get('status')}`)
    }

    if (urlParameters.has('issueType')) {
      filters.push(`issueType=${urlParameters.get('issueType')}`)
    }

    if (filters.length > 0) {
      url += `?${filters.join('&')}`
    }

    navigation(url)
  }, [navigation, selectedPlant?.id, urlParameters])

  /**
   * Handle View Ticket Click Event
   * @param {string} ticketId Ticket ID
   */
  const handleViewTicket = useCallback(
    (ticketId: string) => {
      navigation(`/ticket/${selectedPlant.id}/${ticketId}/overview`)
    },
    [navigation, selectedPlant?.id],
  )



  

  return (
    <PageContainer
      height="100%"
      allowNotifications={true}
      allowUnauthenticated={false}
      pageError={pageError}
      navbarPosition="bottom"
      menuType="main"
      pageTitle="Portfolio Home"
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      <Spacer direction="vertical" amount="20px" display="block" />
      <AlignmentContainer align="center" display="block">
        <DropdownInput
          label="View Data For Plant"
          data={plantDropdownData}
          display="block"
          width="200px"
          onChange={newValue => handlePlantChange(newValue)}
        />
        <Spacer direction="vertical" amount="20px" display="block" />
        <Button
          text="Add Ticket"
          theme="main"
          display="inline"
          callback={() => handleAddTicketClick()}
        />
        <Spacer direction="horizontal" amount="20px" display="inline-block" />
        <Button
          text="Filter"
          theme="main"
          display="inline"
          callback={() => handleModifyFilterClick()}
        />
        <Spacer direction="vertical" amount="20px" display="block" />
        <TextInput
          placeholder="Search for a Ticket ID or Name..."
          display="block"
          onTextChange={newValue => setSearchQuery(newValue)}
        />
        {searchQuery !== '' ? (
          <>
            <Spacer direction="vertical" amount="5px" display="block" />
            <TextElement
              text={`Showing ${filteredData.length} of ${tickets.length} Tickets`}
              theme="paragraph"
              alignment="center"
              display="block"
              colour="grey"
              italics={true}
              sizeOverride="90%"
            />
          </>
        ) : null}
        {filterText !== '' ? (
          <>
            <Spacer direction="vertical" amount="5px" display="block" />
            <TextElement
              text={filterText}
              theme="paragraph"
              alignment="center"
              display="block"
              colour="grey"
              italics={true}
              sizeOverride="90%"
            />
          </>
        ) : null}
      </AlignmentContainer>
      {isApiBusy ? <Loading type="large" alignment="center" /> : null}
      {isApiBusy === false && filteredData.length === 0 ? (
        <TextElement
          text="No Tickets Found"
          theme="paragraph"
          alignment="center"
          display="block"
        />
      ) : null}
      {isApiBusy === false && filteredData.length > 0 ? (
        <>
          <Spacer direction="vertical" amount="20px" display="block" />
          {filteredData.map(ticket => (
            <TicketContainer key={ticket.id}>
              <InfoList
                title={`[${ticket.id}] ${ticket.nodeName}`}
                maxWidth="350px"
                data={[
                  {
                    title: 'Meter Type',
                    data: ticket.meterType,
                  },
                  {
                    title: 'Issue Type',
                    data: ticket.issueType,
                  },
                  {
                    title: 'Severity',
                    data: ticket.severity,
                  },
                  {
                    title: 'Open Date',
                    data: ticket.openDate,
                  },
                  {
                    title: 'Last Detected',
                    data: ticket.lastDetected,
                  },
                  {
                    title: 'Status',
                    data: ticket.status,
                  },
                ]}
              />
              <AlignmentContainer align="center" display="block">
                <Spacer direction="vertical" amount="10px" display="block" />
                <Button
                  text="View Ticket"
                  theme="light"
                  callback={() => handleViewTicket(ticket.id)}
                />
              </AlignmentContainer>
            </TicketContainer>
          ))}
        </>
      ) : null}
      <Spacer direction="vertical" amount="20px" display="block" />
      {/* {console.info('Ticket Data: ', tickets)} */}
      {console.info('plantdropdown Data: ', plantDropdownData)}
      {console.info('selected plant: ', selectedPlant)}
      {console.info('filter data: ', filteredData.length)}
      {console.info('Ticket data: ', tickets.length)}
    </PageContainer>
  )
}

export default TicketsHome
