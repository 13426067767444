/* eslint-disable camelcase */
import { ValidationError } from '../../../_types/validation'
import { NewTicket } from './types'

/**
 * Get the dropdown data for the status dropdown
 * @returns {DropdownData[]} The dropdown data
 *
 * @example
 * ```ts
 * const dropdownData = getStatusDropdownData()
 * ```
 */
const getStatusDropdownData = () => [
  {
    displayValue: 'Open',
    id: 'OPEN',
  },
  {
    displayValue: 'Closed',
    id: 'CLOSED',
  },
]

/**
 * Get the dropdown data for the severity dropdown
 * @returns {DropdownData[]} The dropdown data
 *
 * @example
 * ```ts
 * const dropdownData = getSeverityDropdownData()
 * ```
 */
const getSeverityDropdownData = () => [
  {
    displayValue: 'Minor',
    id: 'Minor',
  },
  {
    displayValue: 'Major',
    id: 'Major',
  },
  {
    displayValue: 'Critical',
    id: 'Critical',
  },
]

/**
 * Get the dropdown data for the issue type dropdown
 * @returns {DropdownData[]} The dropdown data
 *
 * @example
 * ```ts
 * const dropdownData = getIssueTypeDropdownData()
 * ```
 */
const getMeterTypeDropdownData = () => [
  {
    displayValue: 'Inv',
    id: 'INV',
  },
  {
    displayValue: 'Block',
    id: 'BLOCK',
  },
  {
    displayValue: 'SCB',
    id: 'SCB',
  },
]

/**
 * validate the form data
 *
 * @param {NewTicket} ticket The ticket data
 * @returns {ValidationError[]} The validation errors
 *
 * @example
 * ```ts
 * const errors = validate(ticket)
 * ```
 */
const validate = (ticket: NewTicket) => {
  const errors: ValidationError[] = []

  // if (!ticket.plantId || ticket.plantId.toString() === '') {
  //   errors.push({
  //     elementId: 'plantId',
  //     message: 'Plant ID is required',
  //   })
  // }
  if (!ticket.plant_id || ticket.plant_id.toString() === '') {
    errors.push({
      elementId: 'plant_id',
      message: 'Plant ID is required',
    })
  }

  if (
    !ticket.nodeName ||
    ticket.nodeName === '' ||
    !ticket.meterId ||
    ticket.meterId.toString() === ''
  ) {
    errors.push({
      elementId: 'meterId',
      message: 'Meter is required',
    })
  }

  if (!ticket.assignee || ticket.assignee === '') {
    errors.push({
      elementId: 'assignee',
      message: 'Assignee is required',
    })
  }

  if (!ticket.meterType || ticket.meterType === '') {
    errors.push({
      elementId: 'meterType',
      message: 'Meter Type is required',
    })
  }

  if (!ticket.severity || ticket.severity === '') {
    errors.push({
      elementId: 'severity',
      message: 'Severity is required',
    })
  }

  if (!ticket.issueType || ticket.issueType === '') {
    errors.push({
      elementId: 'issueType',
      message: 'Issue Type is required',
    })
  }

  if (!ticket.description || ticket.description === '') {
    errors.push({
      elementId: 'description',
      message: 'Description is required',
    })
  }

  if (!ticket.status || ticket.status === '') {
    errors.push({
      elementId: 'status',
      message: 'Status is required',
    })
  }

  return errors
}

/**
 * Convert the form data to the payload-formatted data
 *
 * @param {NewTicket} ticket The ticket data
 * @param {string} reporterEmail The email of the reporter
 * @returns {any} The payload-formatted data
 *
 * @example
 * ```ts
 * const payload = convertToPayload(ticket, reporterEmail)
 * ```
 */
const convertToPayload = (ticket: NewTicket, reporterEmail: string) => ({
  plant_id: ticket.plant_id,
  ticket_type: ticket.issueType,
  meter_id: ticket.meterId,
  raised_date: ticket.openDate,
  status: ticket.status,
  user: reporterEmail,
  affected_capacity: ticket.affectedCapacity,
  description: ticket.description,
  severity: ticket.severity,
  assigned_to: ticket.assignee,
  comment: ticket.comment,
  close_reason_comment: '',
  start_date_wt: Math.round(Date.now() / 1000).toString() // Include start_date_wt here

})

export {
  getStatusDropdownData,
  getSeverityDropdownData,
  getMeterTypeDropdownData,
  validate,
  convertToPayload,
}
