/* eslint-disable @typescript-eslint/no-explicit-any */
import { log } from '../_utilities/logging'
import { doesExist, formatErrorObject } from '../_utilities/utils'
import { decryptText, encryptText } from './cryptography'

/**
 * Get a value from local storage
 *
 * @param {string} key The key to get
 * @param {any} defaultValue The default value to return if the key does not exist
 * @returns {Promise<string>} The value
 *
 * @example
 * ```ts
 * getLocalStorageItem('key', 'defaultValue').then(value => {
 *  // Do something with the value
 * })
 * ```
 */
const getLocalStorageItem = (key: string, defaultValue: any): Promise<string> =>
  new Promise((resolve, _) => {
    try {
      const value = localStorage.getItem(key)

      if (doesExist(value) && value !== 'undefined') {
        const decryptedValue = decryptText(value, key)
        resolve(decryptedValue)
      } else {
        resolve(defaultValue)
      }
    } catch (error) {
      log(
        '9150PJK8YUXMD4V2A0EO',
        'storage.ts',
        'getLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(defaultValue)
    }
  })

/**
 * Set a value in local storage
 *
 * @param {string} key The key to set
 * @param {string} value The value to set
 * @returns {Promise<boolean>} True if the value was set, false otherwise
 *
 * @example
 * ```ts
 * setLocalStorageItem('key', 'value').then(success => {
 * // Do something with the success
 * })
 */
const setLocalStorageItem = (key: string, value: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      if (value !== 'undefined') {
        const encryptedValue = encryptText(value, key)
        localStorage.setItem(key, encryptedValue)

        resolve(true)
      } else {
        resolve(false)
      }
    } catch (error) {
      log(
        '75U3T16TN47F13NTDKYY',
        'storage.ts',
        'setLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

/**
 * Check if a value exists in local storage
 *
 * @param {string} key The key to check
 * @returns {Promise<boolean>} True if the value exists, false otherwise
 *
 * @example
 * ```ts
 * checkLocalStorageItem('key').then(exists => {
 * // Do something with the exists
 * })
 */
const checkLocalStorageItem = (key: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      const value = localStorage.getItem(key)
      resolve(doesExist(value) && value !== 'undefined')
    } catch (error) {
      log(
        'LR57LARRWLM4YNN0RHDO',
        'storage.ts',
        'checkLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

/**
 * Remove a value from local storage
 *
 * @param {string} key The key to remove
 * @returns {Promise<boolean>} True if the value was removed, false otherwise
 *
 * @example
 * ```ts
 * removeLocalStorageItem('key').then(success => {
 * // Do something with the success
 * })
 */
const removeLocalStorageItem = (key: string): Promise<boolean> =>
  new Promise((resolve, _) => {
    try {
      localStorage.removeItem(key)
      resolve(true)
    } catch (error) {
      log(
        '4NPBGJKTW2FSOGP4KDPY',
        'storage.ts',
        'removeLocalStorageItem',
        'error',
        'Error accessing AsyncStorage',
        formatErrorObject(error),
        true,
      )
      resolve(false)
    }
  })

export {
  checkLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
}
