import React, { useCallback, useContext, useState } from 'react'
import OAuth2Login from 'react-simple-oauth2-login'
import { useNavigate } from 'react-router-dom'

import LogoImage from '../../_assets/images/logo-long-dark.png'
import PageContainer from '../../components/page-container/PageContainer'
import { NotificationContext } from '../../_globals/notifications/notification-context'
import { AzureAuthProps } from '../../_types/azure-auth'
import { customFetch } from '../../_data/api'
import { getEnvironmentSettings } from '../../_utilities/config'
import { useAppDispatch } from '../../_globals/hooks'
import { userSlice } from '../../_globals/user/user-slice'
import Spacer from '../../components/spacer/Spacer'
import Loading from '../../components/loading/Loading'
import TextElement from '../../components/text/Text'
import { removeLocalStorageItem } from '../../_data/storage'
import { log } from '../../_utilities/logging'
import { LoginContainer, Logo } from './styled'
import { getPlantNamesFromIds, getScopeDataFromAccessToken } from './helpers'
import { Environment } from '../../_environments/environment'

const environment = getEnvironmentSettings()

const Login = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const navigation = useNavigate()
  const appDispatch = useAppDispatch()
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)


  const plant_whiteList = Environment.whitePlantList

  /**
   * Handle the sign in success
   *
   * @param {AzureAuthProps} result
   */
  const handleSignInSuccess = useCallback(
    (result: AzureAuthProps) => {
      if (isApiBusy) {
        showNotification({
          title: 'Loading, One Moment',
          dismissAfter: 1500,
          type: 'info',
        })
      } else {
        setIsApiBusy(true)

        const authenticatedUser = getScopeDataFromAccessToken(
          result.access_token,
        )

        customFetch(
          'POST',
          'get_user_plant',
          {
            // eslint-disable-next-line camelcase
            email_id: authenticatedUser.email.toLowerCase(),
          },
          'application/json',
          true,
          true,
        )
          .then(response => {
            if (response.code === 200) {

              // console.info("response.data",response.data)
              const userPlantList = response.data.USER

              // console.info("userPlantList",userPlantList)

            // Filter common plants between plant_whiteList and userPlantList
            const commonPlants = userPlantList.filter((plantId: number) =>
              plant_whiteList.includes(plantId),
            )

            // console.info("commonPlants",commonPlants)


             // Update authenticatedUser with filtered plantList
            const updatedUser = { ...authenticatedUser, plantList: commonPlants }

          
           response.data.USER = commonPlants
            getPlantNamesFromIds(updatedUser, response.data).then(updatedUserWithPlants => {
              appDispatch(userSlice.actions.saveUser(updatedUserWithPlants))
              console.log("Updated User with Plants (Redux):", updatedUserWithPlants)
              navigation('/')
            })
          } 
            else {
              log(
                'GFASIPNPO977CQHWY8I2',
                'Login.tsx',
                'handleSignInSuccess',
                'error',
                'Get User Plant Error',
                { response, authenticatedUser },
              )

              showNotification({
                title: 'User Not Found',
                dismissAfter: 1500,
                type: 'error',
              })
            }
          })
          .catch((error: Error) => {
            log(
              'AGUK7C2QIDGCP98TF6QE',
              'Login.tsx',
              'handleSignInSuccess',
              'error',
              'get_user_plant API Error',
              { error, authenticatedUser },
            )

            showNotification({
              title: 'User Not Found',
              dismissAfter: 1500,
              type: 'error',
            })
            setIsApiBusy(false)
          })
      }
    },
    [isApiBusy, showNotification, appDispatch, navigation],
  )

  /**
   * Handle the sign in failure
   *
   * @param {Error} result
   */
  const handleSignInFailure = useCallback(
    (result: Error) => {
      log(
        'Q6G7MACURJVEPZRU7Q45',
        'Login.tsx',
        'handleSignInFailure',
        'error',
        'Sign In Error',
        result,
      )

      removeLocalStorageItem('user').then(() => {
        setIsApiBusy(false)

        showNotification({
          title: 'Sign In Failed',
          dismissAfter: 3000,
          type: 'error',
        })
      })
    },
    [showNotification],
  )

  return (
    <PageContainer height="100vh" allowNotifications={true} showNavBar={false}>
      <LoginContainer>
        <Logo src={LogoImage} />
        <Spacer direction="vertical" amount="50px" display="block" />
        {isApiBusy ? (
          <>
            <Loading type="wide" alignment="center" />
            <TextElement
              text="Loading Plant Data..."
              alignment="center"
              theme="paragraph"
              display="block"
            />
          </>
        ) : (
          <OAuth2Login
            authorizationUrl={environment.azure.authorizationUrl}
            clientId={environment.azure.clientId}
            redirectUri={environment.azure.redirectUri}
            responseType="token"
            buttonText="Login with Azure AD"
            className="azure-login-button"
            scope="openid"
            onSuccess={result => handleSignInSuccess(result as AzureAuthProps)}
            onFailure={(result: Error) => handleSignInFailure(result)}
          />
        )}
      </LoginContainer>
    </PageContainer>
  )
}

export default Login
