import CryptoJS from 'crypto-js'

/**
 * Encrypt the text
 *
 * @param {string} content The content to encrypt
 * @param {string} nonce The nonce to use for encryption
 * @returns {string} The encrypted text
 *
 * @example
 * ```ts
 * const encryptedText = encryptText('Hello World', '1234567890')
 * ```
 */
const encryptText = (content: string, nonce: string) => {
  const cipher = CryptoJS.AES.encrypt(content, nonce)

  return cipher.toString()
}

/**
 * Decrypt the text
 *
 * @param {string} content The content to decrypt
 * @param {string} nonce The nonce to use for decryption
 * @returns {string} The decrypted text
 *
 * @example
 * ```ts
 * const decryptedText = decryptText('Hello World', '1234567890')
 * ```
 */
const decryptText = (content: string, nonce: string) => {
  const bytes = CryptoJS.AES.decrypt(content, nonce)
  const originalContent = bytes.toString(CryptoJS.enc.Utf8)

  return originalContent
}

export { encryptText, decryptText }
