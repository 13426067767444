const colours = {
  green: '#2DCE89',
  blue: '#00AEEF',
  indigo: '#5E72E4',
  teal: '#0EB1A3',
  yellow: '#F1E14D',
  red: '#F5365C',
  grey: '#3F3F3F',
  darkBlue: '#172B4D',
  background: '#E9ECEF',
}

const fontSize = {
  small: '12px',
  regular: '16px',
  title: '20px',
}

/**
 * Get the applicable fonts
 *
 * @returns {any}
 *
 * ```ts
 * const fonts = font()
 * ```
 */
const font = () => {
  const fonts = {
    light: 'Roboto-Light',
    lightItalics: 'Roboto-LightItalic',
    regular: 'Roboto-Regular',
    regularItalics: 'Roboto-Italic',
    bold: 'Roboto-Bold',
    boldItalics: 'Roboto-BoldItalic',
    black: 'Roboto-Black',
    blackItalics: 'Roboto-BlackItalic',
  }

  return fonts
}

const viewportBreakpoints = {
  phone: [0, 600],
  tablet: [601, 800],
  laptop: [801, 1024],
  desktop: [1025, 1200],
  tv: [1201, 999_999],
}

export { colours, fontSize, font, viewportBreakpoints }
