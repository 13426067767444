import styled from 'styled-components'

import { font } from '../../_globals/theme'

const Label = styled.p`
  color: ${props => props.theme.colour};
`
Label.defaultProps = {
  theme: {
    colour: 'black',
  },
}

const SubLabel = styled.p`
  color: ${props => props.theme.colour};
  font-family: ${font().lightItalics};
  font-size: 10pt;
  padding-left: 5px;
`
SubLabel.defaultProps = {
  theme: {
    colour: 'black',
  },
}

export { Label, SubLabel }
