/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import TextElement from '../../../components/text/Text'
import { customFetch } from '../../../_data/api'
import { colours } from '../../../_globals/theme'
import { getEnvironmentSettings } from '../../../_utilities/config'
import { log } from '../../../_utilities/logging'
import { formatData } from './helpers'
import {
  DateContainer,
  DeviceBlock,
  DeviceContainer,
  DeviceLabel,
  SummaryCard,
  SummaryContainer,
  SummaryTitle,
} from './styled'
import {
  PlantStatusReportPageParams,
  StatusReport,
  StatusReportSummary,
} from './types'

const environment = getEnvironmentSettings()
let refreshInterval: NodeJS.Timeout

/**
 * Plant Status Report Page
 * @returns {JSX.Element}
 */
const PlantStatusReport = (): JSX.Element => {
  const { id } = useParams<PlantStatusReportPageParams>()
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)
  const [data, setData] = useState<StatusReport[]>([])

  /**
   * The plant id, based on the url parameter
   */
  const plantId = useMemo(() => Number.parseInt(id.split('-')[0], 10), [id])

  /**
   * The plant name, based on the url parameter
   */
  const plantName = useMemo(() => id.split('-')[1], [id])

  /**
   * Meter Types formatted for the dropdown
   */
  const meterTypesDropdownData = useMemo<DropdownData[]>(
    () => [
      {
        displayValue: 'Inv',
        id: 'INV',
      },
      {
        displayValue: 'SCB',
        id: 'SCB',
      },
    ],
    [],
  )

  /**
   * The device summary, based on the device status
   */
  const deviceSummary = useMemo<StatusReportSummary>(
    () => ({
      communicating: data.filter(
        dataPoint => dataPoint.status === 'communicating',
      ).length,
      communicationError: data.filter(
        dataPoint => dataPoint.status === 'communication-error',
      ).length,
      deviceBreakdown: data.filter(
        dataPoint => dataPoint.status === 'device-breakdown',
      ).length,
      spare: data.filter(dataPoint => dataPoint.status === 'spare').length,
    }),
    [data],
  )

  /**
   * Fetch page data from the API
   * @param {string} meterType The meter type to fetch data for
   */
  const fetchData = useCallback(
    (meterType?: string) => {
      const sanitizedMeterType = meterType || meterTypesDropdownData[0].id

      customFetch(
        'POST',
        `data/device_status`,
        {
          meter_type: sanitizedMeterType,
          plant_id: plantId,
        },
        'application/json',
        false,
        false,
      )
        .then(result => {
          if (result.code === 200) {
            setData(formatData(result.data.meters))
          } else {
            log(
              '1FY6SIOL2TT0QUWTZFJ4',
              'PlantStatusReport.tsx',
              'fetchData',
              'error',
              'Error fetching data',
              { sanitizedMeterType, plantId, result },
            )
            setPageError('Error fetching data')
          }
        })
        .catch((error: Error) => {
          log(
            'YPP34TEUAGBXZQOM7ASC',
            'PlantStatusReport.tsx',
            'fetchData',
            'error',
            'deviceStatus API error',
            { sanitizedMeterType, plantId, error },
          )
          setPageError('Error fetching data')
        })
        .finally(() => {
          setIsApiBusy(false)
        })
    },
    [meterTypesDropdownData, plantId],
  )

  useEffect(() => {
    clearInterval(refreshInterval)

    refreshInterval = setInterval(() => {
      fetchData()
    }, environment.dataRefreshInterval)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [fetchData])

  return (
    <PageContainer
      offsetTop="20px"
      offsetBottom="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      pageError={pageError}
      onlyShowBackButton={false}
      navbarPosition="top"
      menuType="plant"
      pageTitle={plantName}
      showNavBar={true}
      onPageReady={() => fetchData()}>
      <DateContainer>
        <DropdownInput
          label="Select Meter Type"
          width="300px"
          data={meterTypesDropdownData}
          onChange={newValue => fetchData(newValue)}
        />
      </DateContainer>
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <>
          <SummaryContainer>
            <SummaryCard>
              <SummaryTitle theme={{ colour: colours.green }}>
                COMMUNICATING
              </SummaryTitle>
              <TextElement
                text={deviceSummary.communicating.toString()}
                theme="h2"
                alignment="center"
                display="block"
              />
            </SummaryCard>
            <SummaryCard>
              <SummaryTitle theme={{ colour: colours.yellow }}>
                COMMUNICATION ERROR
              </SummaryTitle>
              <TextElement
                text={deviceSummary.communicationError.toString()}
                theme="h2"
                alignment="center"
                display="block"
              />
            </SummaryCard>
            <SummaryCard>
              <SummaryTitle theme={{ colour: colours.red }}>
                DEVICE BREAKDOWN
              </SummaryTitle>
              <TextElement
                text={deviceSummary.deviceBreakdown.toString()}
                theme="h2"
                alignment="center"
                display="block"
              />
            </SummaryCard>
            <SummaryCard>
              <SummaryTitle theme={{ colour: colours.grey }}>
                SPARE
              </SummaryTitle>
              <TextElement
                text={deviceSummary.spare.toString()}
                theme="h2"
                alignment="center"
                display="block"
              />
            </SummaryCard>
          </SummaryContainer>
          <DeviceContainer>
            {data.map(dataPoint => (
              <DeviceBlock
                key={dataPoint.meterId}
                tabIndex={0}
                className={dataPoint.status}>
                <DeviceLabel>{dataPoint.formattedTag}</DeviceLabel>
              </DeviceBlock>
            ))}
          </DeviceContainer>
        </>
      )}
    </PageContainer>
  )
}

export default PlantStatusReport
