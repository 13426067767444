/* eslint-disable @typescript-eslint/no-explicit-any */
import { InfoTableData } from '../../../components/info-table/types'

/**
 * Gather the table data from the raw data
 *
 * @param {any} rawData The raw data
 * @returns {InfoTableData[]} The table data
 *
 * @example
 * ```ts
 * const tableData = gatherTableData(rawData)
 * ```
 */
const gatherTableData = (rawData: any): InfoTableData[] => {
  const rows: InfoTableData[] = []

  Object.keys(rawData).forEach((key: string) => {
    rows.push({
      header: key,
      value: rawData[key],
    })
  })

  const sanitizedRows = rows.filter(
    row => row.value !== null && row.value !== '-',
  )

  return sanitizedRows
}

export { gatherTableData }
