/* eslint-disable camelcase */
import React, { useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router'

import DownloadIcon from '../../../_assets/icons/download-solid.svg'

import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextElement from '../../../components/text/Text'
import { customFetch } from '../../../_data/api'
import { RawTicket } from '../home/types'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { log } from '../../../_utilities/logging'
import {
  FileContainer,
  FileContentContainer,
  FileDownloadIcon,
  FileImage,
  FileTitle,
  FormContainer,
  UploadTarget,
} from './styled'
import { DocumentsPageParams, FileType, TicketDocument } from './types'
import { formatData } from '../home/helpers'

/**
 * Documents Page
 * @returns {JSX.Element}
 */
const Documents = (): JSX.Element => {
  const { plantId, ticketId } = useParams<DocumentsPageParams>()
  const { showNotification } = useContext(NotificationContext)
  const [imageFiles, setImageFiles] = useState<TicketDocument[]>([])
  const [documentFiles, setDocumentFiles] = useState<TicketDocument[]>([])
  const [apisToLoad, setApisToLoad] = useState<number>(3)
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [pageTitle, setPageTitle] = useState<string>('Loading...')
  const [pageError, setPageError] = useState<string>('')

  /**
   * Event handler for when the page is ready
   */
  const handlePageReady = useCallback(() => {
    // get images
    customFetch(
      'GET',
      `tickets/files/image/${ticketId}`,
       null,
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          // eslint-disable-next-line no-restricted-syntax
          console.log(result.data)
          // Format the image data from the response
        // const formattedImages = result.data.files.map((fileUrl: string, index: number) => ({
        //   id: index.toString(), // Use index or another unique identifier
        //   title: `Image ${index + 1}`, // Generate a title or use other metadata if available
        //   url: fileUrl,
        // }));
        const formattedImages = result.data.files.map((fileUrl: string, index: number) => {
          // Extract the image name between /image/ and ?
          const title = fileUrl.substring(
            fileUrl.lastIndexOf('/image/') + 7,
            fileUrl.indexOf('?', fileUrl.lastIndexOf('/image/'))
          );
          
          return {
            id: index.toString(),
            title: title,
            url: fileUrl,
          };
        });

        setImageFiles(formattedImages);

          
        } else {
          log(
            'K8LEX8EM15HA7A5JIHPZ',
            'Documents.tsx',
            'handlePageReady',
            'error',
            'Error loading images',
            { result, ticketId },
          )

          setPageError('Error loading images')
          showNotification({
            title: 'Error loading images',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .catch((error: Error) => {
        log(
          '7WMYZ5IYVLMNTGZNL8KZ',
          'Documents.tsx',
          'handlePageReady',
          'error',
          'Error loading images',
          { error, ticketId },
        )

        setPageError('Error loading images')
        showNotification({
          title: 'Error loading images',
          dismissAfter: 1500,
          type: 'error',
        })
      })
      .finally(() => {
        setApisToLoad(previous => previous - 1)
      })

    // get documents
    customFetch(
      'GET',
      `tickets/files/doc/${ticketId}`,
      null,
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          // eslint-disable-next-line no-restricted-syntax
          console.log(result.data)
          // setDocumentFiles(result.data)

          // const formattedDocs = result.data.files.map((fileUrl: string, index: number) => ({
          //   id: index.toString(), // Use index or another unique identifier
          //   title: `Doc ${index + 1}`, // Generate a title or use other metadata if available
          //   url: fileUrl,
          // }));
          const formattedDocs = result.data.files.map((fileUrl: string, index: number) => {
            // Extract the file name between /doc/ and ?
            const title = fileUrl.substring(
              fileUrl.lastIndexOf('/doc/') + 5,
              fileUrl.indexOf('?', fileUrl.lastIndexOf('/doc/'))
            );
            
            return {
              id: index.toString(),
              title: title,
              url: fileUrl,
            };
          });
  
          setDocumentFiles(formattedDocs);

        } else {
          log(
            'VT3225XGZWVZTZVSF3QY',
            'Documents.tsx',
            'handlePageReady',
            'error',
            'Error loading documents',
            { result, ticketId },
          )

          setPageError('Error loading documents')
          showNotification({
            title: 'Error loading documents',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .catch((error: Error) => {
        log(
          'NWS3OVI76935QJ7BDCVZ',
          'Documents.tsx',
          'handlePageReady',
          'error',
          'Error loading documents',
          { error, ticketId },
        )

        setPageError('Error loading documents')
        showNotification({
          title: 'Error loading documents',
          dismissAfter: 1500,
          type: 'error',
        })
      })
      .finally(() => {
        setApisToLoad(previous => previous - 1)
      })

      const startDate = new Date()
      startDate.setHours(0, 0, 0, 1)
      const endDate = new Date()
      endDate.setHours(23, 55, 59, 0)

    // get ticket data
    customFetch(
      'POST',
      'tickets',
      {
        plant_id: plantId,
        start_date: Math.round(startDate.getTime() / 1000).toString(),
        end_date: Math.round(endDate.getTime() / 1000).toString(),
      },
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const rawTicket: RawTicket = result.data.anomalies
          setPageTitle(`[${rawTicket?.ticket_id}] ${rawTicket?.node_name}`)
        } else {
          log(
            'JM683W7DLKPTNK2R91N0',
            'Documents.tsx',
            'handlePageReady',
            'error',
            'Error loading ticket data',
            { result, plantId },
          )

          setPageError('Error loading ticket data')
          showNotification({
            title: 'Error loading ticket data',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .catch((error: Error) => {
        log(
          'HR7H1OK17ZAWWOEX35VS',
          'Documents.tsx',
          'handlePageReady',
          'error',
          'Error loading ticket data',
          { error, plantId },
        )

        setPageError('Error loading ticket data')
        showNotification({
          title: 'Error loading ticket data',
          dismissAfter: 1500,
          type: 'error',
        })
      })
      .finally(() => {
        setApisToLoad(previous => previous - 1)
      })
  }, [plantId, showNotification])



  /**
   * Handle file upload
   *
   * @param {React.ChangeEvent<HTMLInputElement>} uploadEvent Upload event
   * @param {FileType} type File type
   */
  const handleUploadFile = useCallback(
    (uploadEvent: React.ChangeEvent<HTMLInputElement>, type: FileType) => {
      if (uploadEvent.target.files && uploadEvent.target.files.length > 0) {
      const file = uploadEvent.target.files[0];

      const formData = new FormData();
      formData.append("file", file, `${file.name}`);
      // formData.append("name", "file");
      formData.append("filename", `${file.name}`);
      formData.append('ticket_id', ticketId);
      formData.append('file_type', type === 'image' ? 'image' : 'doc');
       


      
      // Log each entry in FormData for debugging (casting to `any` to avoid TypeScript issues)
      (formData as any).forEach((value: any, key: string) => {
        console.info(`${key}:`, value);
      });


        customFetch(
          'POST',
          // `tickets/files/${type}/${ticketId}`,
          `tickets/files/${type === 'image' ? 'image' : 'doc'}/${ticketId}`,  // Updated URL path
          formData,
          'multipart/form-data',
          false,
          false,
        )
          .then(response => {
            if (response.code === 200) {
              showNotification({
                // title: 'File Uploaded',
                title: `${type === 'image' ? 'Image' : 'Document'} Uploaded`,
                dismissAfter: 1500,
                type: 'success',
              })
            } else {
              log(
                '5T1XF2U8UY70SADVY70H',
                'Document.tsx',
                'handleUploadFile',
                'error',
                'Error uploading file',
                // { response, comment, ticketId: id, email: userSelector.email },
              )
    
              showNotification({
                title: 'Error uploading file',
                dismissAfter: 1500,
                type: 'error',
              })
            }
          })
          .catch((error: Error) => {
            log(
              'A6G9BBNITG2Y8HRBXYN0',
              'Document.tsx',
              'handleUploadFile',
              'error',
              'Error uploading file',
              { error},
            )
            showNotification({
              title: 'Error uploading file',
              dismissAfter: 1500,
              type: 'error',
            })
          })
          .finally(() => {
            console.info("Form Data", formData)
            setIsApiBusy(false)
          })
      }
    },
    [ticketId],
  )

  /**
   * Handle file upload button click
   * @param {FileType} type File type
   */
  const handleUploadFileClick = useCallback(
    (type: FileType) => {
      if (isApiBusy) {
        showNotification({
          title: 'Please wait',
          type: 'info',
          dismissAfter: 2500,
        })

        return null
      }

      setIsApiBusy(true)
      const elementId =
        type === 'image' ? 'upload-image-target' : 'upload-document-target'

      document.getElementById(elementId).click()
      // Reset isApiBusy after 2500 ms
    setTimeout(() => {
      setIsApiBusy(false);
    }, 2500);
    },
    [isApiBusy, showNotification],
  )

  /**
   * Handle file download
   *
   * @param {FileType} type File type
   * @param {string} id File id
   */
  const handleDownloadFile = useCallback(
    (type: FileType, id: string) => {
      let selectedFile: TicketDocument

      if (type === 'image') {
        const foundFile = imageFiles.find(fileData => fileData.id === id)
        if (foundFile) {
          selectedFile = foundFile
        }
      } else {
        const foundFile = documentFiles.find(fileData => fileData.id === id)
        if (foundFile) {
          selectedFile = foundFile
        }
      }

      if (selectedFile) {
        // create a hidden link with the download url
        const link = document.createElement('a')
        link.href = selectedFile.url
        link.download = selectedFile.title
        link.style.display = 'none'

        // append the link to the body
        document.body.append(link)

        // trigger the download
        link.click()
      }
    },
    [documentFiles, imageFiles],
  )

  return (
    <PageContainer
      allowNotifications={true}
      allowUnauthenticated={false}
      navbarPosition="top"
      menuType="ticket"
      pageTitle={pageTitle}
      showNavBar={true}
      pageError={pageError}
      onPageReady={() => handlePageReady()}>
      {apisToLoad > 0 ? (
        <Loading type="large" alignment="center" />
      ) : (
        <FormContainer>
          <Spacer direction="vertical" amount="30px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Upload Document"
              theme="light"
              display="inline-block"
              callback={() => handleUploadFileClick('document')}
            />
            <Spacer
              direction="horizontal"
              amount="20px"
              display="inline-block"
            />
            <Button
              text="Upload Image"
              theme="light"
              display="inline-block"
              callback={() => handleUploadFileClick('image')}
            />
          </AlignmentContainer>
          <Spacer direction="vertical" amount="30px" display="block" />
          <TextElement
            text="Documents"
            theme="h2"
            alignment="center"
            display="block"
            width="100%"
          />
          {documentFiles.length === 0 ? (
            <>
              <Spacer direction="vertical" amount="20px" display="block" />
              <TextElement
                text="No documents"
                theme="paragraph"
                alignment="center"
              />
            </>
          ) : null}
          {documentFiles.map(documentData => (
            <FileContainer key={documentData.id}>
              <FileContentContainer>
                <FileTitle>{documentData.title}</FileTitle>
                <FileDownloadIcon
                  src={DownloadIcon}
                  onClick={() =>
                    handleDownloadFile('document', documentData.id)
                  }
                />
              </FileContentContainer>
            </FileContainer>
          ))}
          <Spacer direction="vertical" amount="20px" display="block" />
          <TextElement
            text="Images"
            theme="h2"
            alignment="center"
            display="block"
            width="100%"
          />
          {imageFiles.length === 0 ? (
            <>
              <Spacer direction="vertical" amount="20px" display="block" />
              <TextElement
                text="No Images"
                theme="paragraph"
                alignment="center"
              />
            </>
          ) : null}
          {imageFiles.map(imageData => (
            <FileContainer key={imageData.id}>
              <FileImage src={imageData.url} />
              <FileContentContainer>
                <FileTitle>{imageData.title}</FileTitle>
                <FileDownloadIcon
                  src={DownloadIcon}
                  onClick={() => handleDownloadFile('image', imageData.id)}
                />
              </FileContentContainer>
            </FileContainer>
          ))}
          <Spacer direction="vertical" amount="30px" display="block" />
        </FormContainer>
      )}
      <UploadTarget
        id="upload-document-target"
        type="file"
        onChange={uploadEvent => handleUploadFile(uploadEvent, 'document')}
      />
      <UploadTarget
        id="upload-image-target"
        type="file"
        onChange={uploadEvent => handleUploadFile(uploadEvent, 'image')}
      />
    </PageContainer>
  )
}

export default Documents
