/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextElement from '../../../components/text/Text'
import { customFetch } from '../../../_data/api'
import { getEnvironmentSettings } from '../../../_utilities/config'
import { log } from '../../../_utilities/logging'
import { formatData } from './helpers'
import { Card, CardInfo, CardValue, Change } from './styled'
import { PlantOverviewData, PlantOverviewPageParams } from './types'

const environment = getEnvironmentSettings()
let refreshInterval: NodeJS.Timeout

/**
 * Plant Overview Page
 * @returns {JSX.Element}
 */
const PlantOverview = (): JSX.Element => {
  const { id } = useParams<PlantOverviewPageParams>()
  const [overviewData, setOverviewData] = useState<PlantOverviewData[]>([])

  /**
   * The plant id, based on the url parameter
   */
  const plantId = useMemo(() => Number.parseInt(id.split('-')[0], 10), [id])

  /**
   * The plant name, based on the url parameter
   */
  const plantName = useMemo(() => id.split('-')[1], [id])

  /**
   * Flag to indicate if the data has loaded
   */
  const hasLoaded = useMemo(() => overviewData.length > 0, [overviewData])

  /**
   * Sort the overview data by measurement title
   */
  const sortedOverviewData = useMemo(
    () =>
      overviewData.sort((a, b) => {
        if (a.measurementTitle < b.measurementTitle) {
          return -1
        }
        if (a.measurementTitle > b.measurementTitle) {
          return 1
        }
        return 0
      }),
    [overviewData],
  )

  /**
   * Handle the page ready event
   */
  const handlePageReady = useCallback(() => {
    const startDate = new Date()
    startDate.setHours(0, 0, 0, 1)
    const fetchParameters = {
      meter_id: 1,
      plant_id: plantId,
      start_date: Math.round(startDate.getTime() / 1000).toString(),
    }

    // Fetch the power card data
    customFetch(
      'POST',
      'power_new_card',
      fetchParameters,
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const fetchedData = formatData(result.data.plots)

          console.info("power api response: ",fetchedData)

          if (fetchedData && !Number.isNaN(fetchedData.currentValue)) {
            setOverviewData(previous => [
              ...previous.filter(
                previousData =>
                  previousData.measurementTitle !==
                  fetchedData.measurementTitle,
              ),
              fetchedData,
            ])
          }
        } else {
          log(
            'YYWGNYLB5A2P7XSY3100',
            'PlantOverview.tsx',
            'handlePageReady',
            'error',
            'Power Card Error',
            { result, fetchParameters },
          )
        }
      })
      .catch((error: Error) => {
        log(
          '0L6TG8HVRL6MLLYMHTBA',
          'PlantOverview.tsx',
          'handlePageReady',
          'error',
          'powerCard Error',
          { error, fetchParameters },
        )
      })

    // Fetch the ghi card data
    customFetch(
      'POST',
      'ghi_new_card',
      {
        plant_id: plantId,
        impact_date: Math.round(startDate.getTime() / 1000).toString(),
      },
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const fetchedData = formatData(result.data.plots)

          if (fetchedData && !Number.isNaN(fetchedData.currentValue)) {
            setOverviewData(previous => [
              ...previous.filter(
                previousData =>
                  previousData.measurementTitle !==
                  fetchedData.measurementTitle,
              ),
              fetchedData,
            ])
          }
        } else {
          log(
            'XF3YPQTEX0C4JDF69A05',
            'PlantOverview.tsx',
            'handlePageReady',
            'error',
            'GHI Card Error',
            { result, fetchParameters },
          )
        }
      })
      .catch((error: Error) => {
        log(
          'ZW1Z7OYPQMYVNLNUJSQO',
          'PlantOverview.tsx',
          'handlePageReady',
          'error',
          'ghiCard Error',
          {
            error,
            fetchParameters,
          },
        )
      })

    // Fetch the gii card data
    customFetch(
      'POST',
      'gii_new_card',
      {
        plant_id: plantId,
        impact_date: Math.round(startDate.getTime() / 1000).toString(),
      },
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const fetchedData = formatData(result.data.plots)

          if (fetchedData && !Number.isNaN(fetchedData.currentValue)) {
            setOverviewData(previous => [
              ...previous.filter(
                previousData =>
                  previousData.measurementTitle !==
                  fetchedData.measurementTitle,
              ),
              fetchedData,
            ])
          }
        } else {
          log(
            'KBEZGX16VSXI9ELNJSMH',
            'PlantOverview.tsx',
            'handlePageReady',
            'error',
            'GII Card Error',
            { result, fetchParameters },
          )
        }
      })
      .catch((error: Error) => {
        log(
          'V1YPKYEEBNNYHC6JKCVR',
          'PlantOverview.tsx',
          'handlePageReady',
          'error',
          'giiCard Error',
          {
            error,
            fetchParameters,
          },
        )
      })

    // Fetch the pr card data
    customFetch(
      'POST',
      'pr_new_card',
      fetchParameters,
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const fetchedData = formatData(result.data.plots)

          if (fetchedData && !Number.isNaN(fetchedData.currentValue)) {
            setOverviewData(previous => [
              ...previous.filter(
                previousData =>
                  previousData.measurementTitle !==
                  fetchedData.measurementTitle,
              ),
              fetchedData,
            ])
          }
        } else {
          log(
            'EXWTV692UMHIY6ELOYXX',
            'PlantOverview.tsx',
            'handlePageReady',
            'error',
            'PR Card Error',
            { result, fetchParameters },
          )
        }
      })
      .catch((error: Error) => {
        log(
          '8BE60T1TCLRVWYMYOED2',
          'PlantOverview.tsx',
          'handlePageReady',
          'error',
          'prCard Error',
          {
            error,
            fetchParameters,
          },
        )
      })

    // Fetch the energy card data
    customFetch(
      'POST',
      'energy_new_card',
      fetchParameters,
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const fetchedData = formatData(result.data.plots)

          if (fetchedData && !Number.isNaN(fetchedData.currentValue)) {
            setOverviewData(previous => [
              ...previous.filter(
                previousData =>
                  previousData.measurementTitle !==
                  fetchedData.measurementTitle,
              ),
              fetchedData,
            ])
          }
        } else {
          log(
            'NDFG0271KI9DV7GBA5RS',
            'PlantOverview.tsx',
            'handlePageReady',
            'error',
            'Energy Card Error',
            { result, fetchParameters },
          )
        }
      })
      .catch((error: Error) => {
        log(
          'GMTBUCM5GPF7TQNIKWMW',
          'PlantOverview.tsx',
          'handlePageReady',
          'error',
          'energyCard Error',
          { error, fetchParameters },
        )
      })
  }, [plantId])

  useEffect(() => {
    clearInterval(refreshInterval)

    refreshInterval = setInterval(() => {
      handlePageReady()
    }, environment.dataRefreshInterval)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [handlePageReady])

  return (
    <PageContainer
      allowNotifications={true}
      allowUnauthenticated={false}
      navbarPosition="top"
      menuType="plant"
      pageTitle={plantName}
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      {hasLoaded === false ? (
        <Loading type="large" />
      ) : (
        <>
          {sortedOverviewData.map((data, index) => (
            <Card key={`${data.attribute}-${index.toString()}`}>
              <CardInfo>
                <TextElement text={data.measurementTitle} theme="h3" />
                <Spacer direction="vertical" amount="10px" display="block" />
                <Change className={data.upOrDown}>
                  {data.formattedChange}
                </Change>
                <TextElement text="Since Previous Day" theme="paragraph" />
              </CardInfo>
              <CardValue>
                <TextElement
                  text={data.currentValue.toFixed(2)}
                  theme="h3"
                  alignment="center"
                  display="block"
                  sizeOverride="150%"
                />
                <TextElement
                  text={data.unit}
                  theme="paragraph"
                  alignment="center"
                  display="block"
                />
              </CardValue>
            </Card>
          ))}
        </>
      )}
    </PageContainer>
  )
}

export default PlantOverview
