import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DefaultTheme } from 'styled-components'

import InputContainer from '../input-container/InputContainer'
import InputLabel from '../input-label/InputLabel'
import AlignmentContainer from '../alignment-container/AlignmentContainer'
import TextElement from '../text/Text'
import { DateInputProps } from './types'
import { RestyledDatePicker } from './styled'

/**
 * A fancy date input, complete with an interactive calendar
 *
 * @param {DateInputProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 * <DateInput
 *  key={question.id}
 *  colourMode="light"
 *  display="block"
 *  onChange={(newValue: Date) => handleDate(newValue)}
 *  />
 * ```
 */
const DateInput = ({
  onChange,
  onUiClose,
  onUiOpen,
  label,
  subLabel,
  elementId,
  initialValue,
  isDisabled,
  hideUi,
  maximumDate,
  minimumDate,
  dateFormat,
  display,
  offsetBottom,
  offsetLeft,
  offsetMode,
  offsetRight,
  offsetTop,
  colourMode,
  width,
}: DateInputProps): JSX.Element => {
  const [value, setValue] = useState<Date>()

  const inputTheme = useMemo(() => {
    const theme: DefaultTheme = {
      background: colourMode === 'dark' ? '#000000' : '#ffffff',
      colour: colourMode === 'dark' ? '#ffffff' : '#000000',
      width: width || 'initial',
    }

    return theme
  }, [colourMode, width])

  const handleChange = useCallback(
    (date: Date) => {
      setValue(date)

      if (onChange) {
        onChange(date)
      }
    },
    [onChange],
  )

  const handleOpen = useCallback(() => {
    if (onUiOpen) {
      onUiOpen()
    }
  }, [onUiOpen])

  const handleClose = useCallback(() => {
    if (onUiClose) {
      onUiClose()
    }
  }, [onUiClose])

  const sanitizedDateFormat = useMemo(
    () => dateFormat || 'MMMM dd, y',
    [dateFormat],
  )

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <InputContainer
      offsetTop={offsetTop}
      offsetRight={offsetRight}
      offsetBottom={offsetBottom}
      offsetLeft={offsetLeft}
      offsetMode={offsetMode}
      elementId={elementId}
      display={display}>
      {label ? (
        <InputLabel
          label={label}
          colour={colourMode === 'dark' ? 'white' : 'black'}
        />
      ) : null}
      {subLabel && subLabel !== '' ? (
        <AlignmentContainer align="left" display="block">
          <TextElement
            text={subLabel}
            theme="small"
            alignment="left"
            colour="white"
            display="block"
          />
        </AlignmentContainer>
      ) : null}
      <RestyledDatePicker
        theme={inputTheme}
        onChange={(date: Date) => handleChange(date)}
        onCalendarOpen={() => handleOpen()}
        onCalendarClose={() => handleClose()}
        value={value}
        calendarIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
        disabled={isDisabled === true}
        disableCalendar={hideUi === true}
        format={sanitizedDateFormat}
        minDate={minimumDate || null}
        maxDate={maximumDate || null}
      />
    </InputContainer>
  )
}

export default DateInput
