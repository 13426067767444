import styled from 'styled-components'
import { font, fontSize } from '../../_globals/theme'

const Container = styled.div`
  width: 100%;
  max-width: 95%;
  margin: 20px auto;
  padding: 20px 0;
`

const TableContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  overflow-x: auto;
`

const TableHead = styled.thead`
  border-bottom: 1px solid #484848;
`

const Row = styled.tr`
  &:not(:last-of-type) {
    border: none;
  }
`

const Header = styled.th`
  font-family: ${font().bold};
  font-size: ${fontSize.regular};
  width: 200px;
  vertical-align: middle;
  border: none;
  padding: 10px;
`

const Data = styled.p`
  font-family: ${font().regular};
  font-size: ${fontSize.regular};
  width: 100%;
  vertical-align: middle;
`

const HiddenInput = styled.input`
  color: white;
  background: none;
  border: none;
  outline: none;
  font-size: ${fontSize.regular};
  max-width: calc(100% - 200px);
  width: 100%;
`

const Cell = styled.td`
  border: none;
  padding: 10px 5px;
  text-align: center;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;

  &.as-cards {
    ${TableHead} {
      display: none;
    }
    ${Row} {
      display: block;
    }
    ${Cell} {
      display: block;
      text-align: right;
    }
    ${Cell}::before {
      content: attr(data-label);
      float: left;
      text-align: left;
      font-weight: bold;
    }
  }
`

export {
  Container,
  Data,
  Header,
  Row,
  HiddenInput,
  Cell,
  Table,
  TableContainer,
  TableHead,
}
