import styled from 'styled-components'
import DatePicker from 'react-date-picker'

const RestyledDatePicker = styled(DatePicker)`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.colour};
  width: ${props => props.theme.width};
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 5px;

  & .react-date-picker__wrapper {
    border: none;
  }
`
RestyledDatePicker.defaultProps = {
  theme: {
    background: '#ffffff',
    colour: '#000000',
    width: 'initial',
  },
}

export { RestyledDatePicker }
