/* eslint-disable id-length */
import styled from 'styled-components'
import { font } from '../../_globals/theme'

const ContentContainer = styled.div`
  display: block;
  text-align: ${props => props.theme.justify};
  width: 100%;
`
ContentContainer.defaultProps = {
  theme: {
    justify: 'left',
  },
}

const RestyledTable = styled.table`
  width: ${props => props.theme.width};
  border-collapse: collapse;
  margin-left: ${props => props.theme.marginLeft};
  margin-right: ${props => props.theme.marginRight};
`
RestyledTable.defaultProps = {
  theme: {
    width: '100%',
    marginLeft: 'none',
    marginRight: 'none',
  },
}

const Tr = styled.tr``

const Th = styled.th`
  text-align: ${props => props.theme.justify};
  font-family: ${font().bold};
  border: none;
  padding: 10px;
`
Th.defaultProps = {
  theme: {
    justify: 'center',
  },
}

const Td = styled.td`
  text-align: ${props => props.theme.justify};
  border: none;
  padding: 5px 10px;
`
Td.defaultProps = {
  theme: {
    justify: 'center',
  },
}

const CellData = styled.p`
  margin: 0;
  padding: 0;
`

const HiddenElement = styled.input`
  border: none;
  background: none;
  color: white;
  width: 100%;
  outline: none !important;
`

const TableHeader = styled.thead`
  border-bottom: 1px solid grey;
`

export {
  ContentContainer,
  RestyledTable,
  Tr,
  Th,
  Td,
  CellData,
  HiddenElement,
  TableHeader,
}
