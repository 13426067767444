import styled from 'styled-components'

import { colours } from '../../../../../../_globals/theme'

const Title = styled.p`
  text-align: center;
  transition: color 0.3s;
  color: ${colours.grey};
`

const Icon = styled.img`
  height: 40px;
  display: block;
  margin: auto;
  padding-bottom: 10px;
  transition: filter 0.3s;
`

const Link = styled.a`
  display: inline-block;
  cursor: pointer;
  padding: 12px 0;
  font-size: 0.9rem;
  flex: 1;
  transition: background-color 0.3s;
  position: relative;

  &.small {
    ${Icon} {
      height: 30px;
      padding-bottom: 5px;
    }
  }

  &.active {
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100px;
      height: 2px;
      background-color: ${colours.darkBlue};
    }

    &.top::after {
      top: 0;
    }
    &.bottom::after {
      bottom: 0;
    }

    ${Title} {
      color: ${colours.darkBlue};
    }
  }
`

export { Link, Icon, Title }
