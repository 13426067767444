/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { customFetch } from '../../_data/api'
import { AzureAuthUser } from '../../_types/azure-auth'
import { DisplayUser, UserPlants } from '../../_types/user'
import { log } from '../../_utilities/logging'

/**
 * Get the scope data from the access token
 *
 * @param {string} rawAccessToken The access token
 * @returns {AzureAuthUser} The scope data
 *
 * @example
 * ```ts
 *   const scopeData = getScopeDataFromAccessToken(accessToken)
 * ```
 */
const getScopeDataFromAccessToken = (rawAccessToken: string) => {
  try {
    // parse out the token payload
    const accessToken = rawAccessToken.split('.')[1]

    // replace the base64 url encoding with base64 encoding
    const accessTokenAsBase64 = accessToken
      .replace(/-/gu, '+')
      .replace(/_/gu, '/')

    // convert the base64 string to a json string
    const jsonPayload = decodeURIComponent(
      [...window.atob(accessTokenAsBase64)]
        .map(
          character =>
            // eslint-disable-next-line unicorn/prefer-code-point
            `%${`00${character.charCodeAt(0).toString(16)}`.slice(-2)}`,
        )
        .join(''),
    )

    // convert the json string to a javascript object
    const authenticatedUser: AzureAuthUser = JSON.parse(jsonPayload)

    return authenticatedUser
  } catch (error) {
    log(
      '9X6C5QELNVKLTLW5CZIE',
      'login/helpers.ts',
      'getScopeDataFromAccessToken',
      'error',
      'Error parsing scope data',
      { error, rawAccessToken },
    )
  }
}

const processPlantIdsInBatches = (
  plantIds: number[],
  isAdmin: boolean,
  startIndex = 0,
  batchSize = 3,
  accumulatedPlants: UserPlants[] = [],
): Promise<UserPlants[]> => {
  const startDate = new Date()
  startDate.setHours(0, 0, 0, 1)
  const endDate = new Date()
  endDate.setHours(23, 55, 59, 0)

  if (startIndex >= plantIds.length) {
    return Promise.resolve(accumulatedPlants)
  }

  const batch = plantIds.slice(startIndex, startIndex + batchSize)
  const batchPromises = batch.map(
    (plantId: number) =>
      new Promise<UserPlants | null>(childResolve => {
        customFetch(
          'POST',
          'summary/tables',
          {
            plant_id_list: [plantId],
            start_date: Math.round(startDate.getTime() / 1000).toString(),
            end_date: Math.round(endDate.getTime() / 1000).toString(),
          },
          'application/json',
          false,
          true,
        )
          .then(response => {
            if (response) {
              const plantName =
                (response?.data as any)?.data?.summary[0]['PLANT NAME'] ?? ''

              // reformat the plant data
              const fetchedPlant: UserPlants = {
                id: plantId,
                name: plantName,
                asAdmin: isAdmin,
              }

              return childResolve(fetchedPlant)
            }

            return childResolve(null)
          })
          .catch((error: Error) => {
            log(
              'Q6Z59I07V66QCUZ5MBMF',
              'login/helpers.ts',
              'getPlantNamesFromIds',
              'error',
              'summary/tables user error',
              { error, plantId },
            )

            return childResolve(null)
          })
      }),
  )

  return Promise.all(batchPromises).then(batchResults => {
    const validResults = batchResults.filter(value => value !== null)
    const updatedAccumulatedPlants = [...accumulatedPlants, ...validResults]

    return processPlantIdsInBatches(
      plantIds,
      isAdmin,
      startIndex + batchSize,
      batchSize,
      updatedAccumulatedPlants,
    )
  })
}

const getPlantNamesFromIds = (
  user: AzureAuthUser,
  plantData: any,
): Promise<DisplayUser> =>
  new Promise(resolve => {
    // fetch the plant names for the user plants
    processPlantIdsInBatches(plantData.USER, false).then(userPlants =>
      // fetch the plant names for the admin plants
      processPlantIdsInBatches(plantData.ADMIN, true).then(adminPlants => {
        // combine the user data, user plant, and admin plants
        const fetchedPlants = [...userPlants, ...adminPlants]

        return resolve({
          id: user.oid,
          email: user.email.toLowerCase(),
          firstName: user.given_name,
          lastName: user.family_name,
          displayName: user.name,
          plants: fetchedPlants ?? [],
        })
      }),
    )
  })

export { getScopeDataFromAccessToken, getPlantNamesFromIds }
