import { setLocalStorageItem } from '../../../_data/storage'
import { colours } from '../../../_globals/theme'
import { UserPlants } from '../../../_types/user'
import { generateId } from '../../../_utilities/utils'
import { PlantCard, PossiblePlants, RawPlantCard } from './types'

/**
 * Format the data from the API into a format
 *
 * @param {RawPlantCard[]} data The raw data from the API
 * @param {UserPlants[]} plants The user's plants
 * @returns {PlantCard[]} The formatted data
 *
 * @example
 * ```ts
 * const formattedData = formatData(rawData, userPlants)
 * ```
 */
const formatData = (data: RawPlantCard[], plants: UserPlants[]): PlantCard[] =>
  data.map(item => {
    let colour = colours.background

    if (item.color === 1) {
      colour = 'green'
    } else if (item.color === 0) {
      colour = 'yellow'
    } else if (item.color === -1) {
      colour = 'red'
    }

    return {
      plantName: item['PLANT NAME'] ?? '',
      capacity: item['CAPACITY (MWp)'] ?? 0,
      actualEnergy: item['ACTUAL ENERGY (MWH)'] ?? 0,
      ghiGii: item['GHI/GII'] ?? '',
      pr: item['PR (%)'] ?? 0,
      colour,
      id: item.plant_id.toString(), // Store plant_id here
      originalId:
        plants
          .find(plant => plant.name === item['PLANT NAME'])
          ?.id?.toString() ?? generateId(20),
    }
  })

/**
 * Save the possible plants to local storage
 *
 * @param {PlantCard[]} plants The plants
 * @param {string} selectedPlantId The selected plant ID
 * @returns {void}
 *
 * @example
 * ```ts
 * savePossiblePlants(plants, selectedPlantId)
 * ```
 */
// const savePossiblePlants = (plants: PlantCard[], selectedPlantId: string) => {
//   const selectedPlant = plants.find(plant => plant.id === selectedPlantId)

//   const plantIndex: PossiblePlants = {
//     currentPlant: {
//       id: selectedPlant.id.toString(),
//       name: selectedPlant.plantName,
//     },
//     plants: plants.map(plantData => ({
//       id: plantData.id,
//       name: plantData.plantName,
//     })),
//   }
//   setLocalStorageItem('possible-plants', JSON.stringify(plantIndex))
// }

const savePossiblePlants = (plants: PlantCard[], selectedPlantId: string) => {
  const selectedPlant = plants.find(plant => plant.id === selectedPlantId);

  if (!selectedPlant) {
    console.error(`Selected plant with ID ${selectedPlantId} not found in plants array.`);
    return;
  }

  const plantIndex: PossiblePlants = {
    currentPlant: {
      id: selectedPlant.id.toString(),
      name: selectedPlant.plantName,
    },
    plants: plants.map(plantData => ({
      id: plantData.id,
      name: plantData.plantName,
    })),
  };

  setLocalStorageItem('possible-plants', JSON.stringify(plantIndex));
};


export { formatData, savePossiblePlants }
