import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { Icon, Link, Title } from './styled'
import { NavLinkProps } from './types'

/**
 * Navigation Links within the NavBar
 * @see NavBar
 *
 * @param {NavLinkProps} props
 * @returns {JSX.Element}
 *
 * ```tsx
 *  <NavLink icon={faUserCircle} title="Profile" url="/profile" />
 * ```
 */
const NavLink = ({
  icon,
  selectedIcon,
  title,
  url,
  pagePosition,
  size,
  isActive,
  onClick,
}: NavLinkProps): JSX.Element => {
  const navigation = useNavigate()

  const className = useMemo(() => {
    const classes: string[] = [size]

    if (pagePosition === 'bottom') {
      classes.push('top')
    } else {
      classes.push('bottom')
    }

    if (isActive) {
      classes.push('active')
    }

    return classes.join(' ')
  }, [isActive, pagePosition, size])

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(url)
    } else {
      navigation(url)
    }
  }, [navigation, onClick, url])

  return (
    <Link onClick={() => handleClick()} className={className}>
      <Icon src={isActive ? selectedIcon : icon} />
      <Title>{title}</Title>
    </Link>
  )
}

export default NavLink
