import styled from 'styled-components'

const FormContainer = styled.div`
  max-width: 400px;
  margin: auto;
`

const FileContainer = styled.div`
  border: 1px solid grey;
  max-width: 400px;
  width: 90%;
  margin: 20px auto;
`

const FileImage = styled.img`
  width: 100%;
`

const FileTitle = styled.p`
  flex: 1;
  padding: 5px;
`

const FileDownloadIcon = styled.img`
  width: 25px;
  cursor: pointer;
`

const FileContentContainer = styled.div`
  display: flex;
  padding: 10px;
`

const UploadTarget = styled.input`
  display: none;
`

export {
  FileContainer,
  FileImage,
  FileTitle,
  FileDownloadIcon,
  FileContentContainer,
  FormContainer,
  UploadTarget,
}
