import React, { useEffect } from 'react'

import Loading from '../../components/loading/Loading'
import PageContainer from '../../components/page-container/PageContainer'
import Spacer from '../../components/spacer/Spacer'
import TextElement from '../../components/text/Text'

/**
 * Login Success Page
 *
 * This page serves as a temporary oauth redirect
 * until the login process has completed and the user
 * is redirected to the main app
 *
 * @returns {JSX.Element}
 */
const LoginSuccess = (): JSX.Element => {
  useEffect(() => {
    setTimeout(() => {
      window.close()
    }, 2000)
  }, [])

  return (
    <PageContainer height="100vh" allowNotifications={true} showNavBar={false}>
      <Spacer direction="vertical" amount="50px" display="block" />
      <TextElement
        text="Login Successful!"
        theme="h1"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="10px" display="block" />
      <TextElement
        text="If this window does not close automatically, please close it now."
        theme="paragraph"
        alignment="center"
        display="block"
      />
      <Spacer direction="vertical" amount="20px" display="block" />
      <Loading type="wide" alignment="center" />
    </PageContainer>
  )
}

export default LoginSuccess
