// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Roboto-Regular";
  box-sizing: border-box;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  float: none;
}

#northern-devs-link {
  position: absolute;
  top: -100000px;
  left: -1000000px;
  opacity: 0;
}

html,
body {
  background-color: #f4f5f7;
}`, "",{"version":3,"sources":["webpack://./src/_globals/css/global-styles.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,sBAAA;AACF;;AAEA;;;EAGE,UAAA;EACA,SAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,UAAA;AACF;;AAEA;;EAEE,yBAAA;AACF","sourcesContent":["* {\n  font-family: 'Roboto-Regular';\n  box-sizing: border-box;\n}\n\n*,\n*::after,\n*::before {\n  padding: 0;\n  margin: 0;\n  float: none;\n}\n\n#northern-devs-link {\n  position: absolute;\n  top: -100000px;\n  left: -1000000px;\n  opacity: 0;\n}\n\nhtml,\nbody {\n  background-color: #f4f5f7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
