import styled from 'styled-components'

const Container = styled.div`
  display: ${props => props.theme.display};
  padding-left: ${props => props.theme.paddingLeft};
  padding-top: ${props => props.theme.paddingTop};
  padding-right: ${props => props.theme.paddingRight};
  padding-bottom: ${props => props.theme.paddingBottom};
  margin-left: auto;
  margin-top: ${props => props.theme.marginTop};
  margin-right: auto;
  margin-bottom: ${props => props.theme.marginBottom};
  vertical-align: middle;
  width: ${props => props.theme.width};
  max-width: 90%;
`

export { Container }
