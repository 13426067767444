import styled from 'styled-components'

import { font, fontSize } from '../../_globals/theme'

const LoadingContainer = styled.div`
  text-align: ${props => props.theme.justify};
  width: 100%;
`
LoadingContainer.defaultProps = {
  theme: {
    justify: 'center',
  },
}

const LoadingText = styled.p`
  font-family: ${font().regular};
  font-size: ${fontSize.regular};
  width: 100%;
`

const LargeAnimationContainer = styled.div`
  height: 70vh;
  width: 100%;
  min-width: 100%;
  position: relative;
`
const LargeAnimation = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const SmallAnimationContainer = styled.div`
  width: 100%;
  min-width: 100%;
`
const SmallAnimation = styled.img`
  width: 100%;
  max-width: 150px;
`

export {
  LoadingContainer,
  LargeAnimationContainer,
  LoadingText,
  SmallAnimationContainer,
  LargeAnimation,
  SmallAnimation,
}
