/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeviceStatus } from './types'

/**
 * Format the data from the api
 * @param {any[]} data The data from the api
 * @returns {any[]} The formatted data
 *
 * @example
 * ```ts
 * const formattedData = formatData(rawData)
 * ```
 */
const formatData = (data: any[]) =>
  data.map(dataPoint => {
    let formattedStatus: DeviceStatus = 'spare'

    if (dataPoint.meter_status === 2) {
      formattedStatus = 'communicating'
    } else if (dataPoint.meter_status === 1) {
      formattedStatus = 'device-breakdown'
    } else if (dataPoint.meter_status === 0) {
      formattedStatus = 'communication-error'
    } else if (dataPoint.meter_status === -1) {
      formattedStatus = 'spare'
    }

    return {
      meterId: dataPoint.meter_id,
      tag: dataPoint.meter_tag,
      formattedTag: dataPoint.meter_tag.replace(/_/gu, ' '),
      status: formattedStatus,
    }
  })

export { formatData }
