/* eslint-disable camelcase */
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { log } from '../../../_utilities/logging'
import { RawTicket } from '../home/types'
import { FormContainer } from './styled'
import { UpdateFailureReasonPageParams } from './types'
import { current } from '@reduxjs/toolkit'

const userAppSelector = (state: RootState) => state.user

/**
 * Update Failure Reason Page
 * @returns {JSX.Element}
 */
const UpdateStatus = (): JSX.Element => {
  const { plantId, ticketId } = useParams<UpdateFailureReasonPageParams>()
  const userSelector = useAppSelector(userAppSelector)
  const navigation = useNavigate()
  const { showNotification } = useContext(NotificationContext)
  const [statusDropdownData, setStatusDropdownData] = useState<DropdownData[]>([])
  const [assigneeDropdownData, setAssigneeDropdownData] = useState<DropdownData[]>([])
  const [currentAssignee, setCurrentAssignee] = useState<string>('')
  const [currentStatus, setCurrentStatus] = useState<string>('')
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)
  const [assigneeDataLoaded, setAssigneeDataLoaded] = useState<boolean>(false) // New state to track assignee data loading

  /**
   * Event handler for when the save button is clicked
   */
  const handleUpdateStatus = useCallback(() => {
    if (currentStatus === '') {
      showNotification({
        title: 'Must select a status',
        dismissAfter: 1500,
        type: 'error',
      })

      return null
    }

    customFetch(
      'POST',
      `tickets/update_ticket_status_v2`,
      {
        ticket_id: ticketId,
        user: userSelector.email,
        status: currentStatus,
        assigned_to: currentAssignee
      },
      'application/json',
      false,
      false,
    )
      .then(response => {
        console.info("message",response.data.error_message)
         // Check if there's an error message, even if the status code is 200
      if (response.data.error_message) {
        const errorMsg = response.data.error_message;
        log(
          'VAGSMB1XC2JA1ARB1Q6E',
          'UpdateStatus.tsx',
          'handleUpdateStatus',
          'error',
          errorMsg,
          { response, ticketId, email: userSelector.email, currentStatus }
        );
        showNotification({
          title: errorMsg,
          dismissAfter: 1500,
          type: 'error',
        });
      } 
        else if (response.code === 200 ) {
          showNotification({
            title: 'Status Updated',
            dismissAfter: 1500,
            type: 'success',
          })

          navigation(-1)
        } else {
          log(
            'VAGSMB1XC2JA1ARB1Q6E',
            'UpdateStatus.tsx',
            'handleUpdateStatus',
            'error',
            'Error updating status',
            { response, ticketId, email: userSelector.email, currentStatus },
          )
          showNotification({
            title: 'Error updating status',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .catch((error: Error) => {
        log(
          'KY1599557D8WIURLHRK7',
          'UpdateStatus.tsx',
          'handleUpdateStatus',
          'error',
          'Error updating status',
          { error, ticketId, email: userSelector.email, currentStatus },
        )
        showNotification({
          title: 'Error updating status',
          dismissAfter: 1500,
          type: 'error',
        })
      })
  }, [
    currentStatus,
    ticketId,
    userSelector.email,
    showNotification,
    navigation,
    currentAssignee, // Ensure currentAssignee is included in dependency array
  ])

  /**
   * Page ready event handler
   */
  const handlePageReady = useCallback(() => {
    customFetch(
      'POST',
      `tickets/get_next_states`,
      {
        ticket_id: ticketId,
        user: userSelector.email
      },
      'application/json',
      false,
      false,
    )
      .then(result => {
        console.info("get update response",result.data)
        if (result.code === 200) {
          const rawTicket: RawTicket = result.data
          setCurrentStatus(rawTicket.status)
          const dropdownOptions = result.data.map((state: string) => ({
            displayValue: state,
            id: state,
          }))
          setStatusDropdownData(dropdownOptions)
        } else {
          log(
            'AQ11JO8E0IBMMKVHE1NY',
            'UpdateStatus.tsx',
            'handlePageReady',
            'error',
            'Error loading ticket data',
            { result, ticketId, plantId },
          )
          setPageError('Error loading ticket data')
        }
      })
      .catch((error: Error) => {
        log(
          'A8WL5AGS3QVF0DKMT6A4',
          'UpdateStatus.tsx',
          'handlePageReady',
          'error',
          'Error loading ticket data',
          { error, ticketId, plantId },
        )
        setPageError('Error loading ticket data')
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [plantId, ticketId])


 useMemo(() => {
  if(currentStatus) {
    customFetch(
      'GET',
      `data/plant_user/${plantId}`, // Append plantId here
      null, // No need for a body in a GET request
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const rawAssignees = result.data
            const dropdownOptions = rawAssignees.map((assignee: { id: number, email_id: string }) => ({
              displayValue: assignee.email_id,
              // id: assignee.id.toString(),
              id: assignee.email_id, // Store email_id as id in dropdown
            }))
            setAssigneeDropdownData(dropdownOptions)
            setAssigneeDataLoaded(true) // Mark assignee data as loaded
          console.info("get assignee ", result.data)
        } else {
          log(
            'U0WUM0SK8HKOXZN242S7',
            'AddTicket.tsx',
            'handlePlantChange',
            'error',
            'Error fetching plantUsersList',
            { result, plantId: plantId },
          )
          setAssigneeDataLoaded(false) // Assignee data not loaded
        }
      })
      .catch((error: Error) => {
        log(
          '6W9LL9XLMCIHUZBH6JMV',
          'AddTicket.tsx',
          'handlePlantChange',
          'error',
          'Error fetching plantUsersList',
          { error, plantId: plantId },
        )
        setAssigneeDataLoaded(false) // Assignee data not loaded
      })
  }
 }, [currentStatus, plantId,currentAssignee])
  return (
    <PageContainer
      height="100%"
      allowNotifications={true}
      allowUnauthenticated={false}
      navbarPosition="top"
      menuType="ticket"
      onlyShowBackButton={true}
      pageTitle="Update Status"
      pageError={pageError}
      onPageReady={() => handlePageReady()}
      showNavBar={true}>
      {isApiBusy ? (
        <Loading type="large" alignment="center" />
      ) : (
        <FormContainer>
          <DropdownInput
            label="Status"
            data={statusDropdownData}
            unselectedDisplayValue="Select a status"
            initialValue={currentStatus}
            onChange={value => setCurrentStatus(value)}
          />
          <br />
          {assigneeDataLoaded && ( // Conditionally render the assignee dropdown
            <DropdownInput
              label="Assignee"
              data={assigneeDropdownData}
              unselectedDisplayValue="Select an assignee"
              initialValue={currentAssignee} // Set the current assignee here
              onChange={value => setCurrentAssignee(value)} // Update current assignee on change
            />
          )}
          <Spacer direction="vertical" amount="50px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Save"
              theme="main"
              size="large"
              callback={() => handleUpdateStatus()}
            />
          </AlignmentContainer>
          {console.log("current assignee",currentAssignee)}
        </FormContainer>
      )}
    </PageContainer>
  )
}

export default UpdateStatus
