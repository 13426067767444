import styled from 'styled-components'
import { colours, font } from '../../../_globals/theme'

const ListContainer = styled.div`
  text-align: center;
`

const CardHeader = styled.p`
  font-family: ${font().bold};
  color: white;
  padding: 10px;
`

const CardControls = styled.div`
  padding: 20px 0;
`

const CardRow = styled.div`
  display: flex;
  padding: 5px 10px;
`

const CardInfo = styled.div`
  flex: 1;

  &.header {
    text-align: left;
  }

  &.data {
    font-family: ${font().bold};
    text-align: right;
  }
`

const CardContainer = styled.div`
  display: inline-block;
  max-width: 400px;
  width: 90%;
  margin: 20px;
  background-color: white;

  &.green {
    border: 1px solid ${colours.green};

    ${CardHeader} {
      background-color: ${colours.green};
    }
  }

  &.yellow {
    border: 1px solid ${colours.yellow};

    ${CardHeader} {
      background-color: ${colours.yellow};
      color: ${colours.grey};
    }
  }

  &.red {
    border: 1px solid ${colours.red};

    ${CardHeader} {
      background-color: ${colours.red};
    }
  }
`

export {
  CardContainer,
  CardHeader,
  CardControls,
  CardRow,
  CardInfo,
  ListContainer,
}
