import styled from 'styled-components'
import { colours, font } from '../../../_globals/theme'

const Card = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px;
  margin: 20px auto;
  max-width: 500px;
`

const CardInfo = styled.div`
  flex: 1;
`

const CardValue = styled.div`
  flex: 0.7;
`

const Change = styled.div`
  font-family: ${font().bold};
  letter-spacing: 1px;

  &.up {
    color: ${colours.green};
  }
  &.down {
    color: ${colours.red};
  }
`

export { Card, CardInfo, CardValue, Change }
