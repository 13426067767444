/**
 * Scroll a dom element into the viewport
 *
 * @param {string} elementId - Element ID
 * @returns {void}
 *
 * ```ts
 * scrollElementIntoView('checkout-email')
 * ```
 */
const scrollElementIntoView = (elementId: string) => {
  try {
    const element = document.getElementById(elementId)

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    })
  } catch (error) {
    console.error(error)
  }
}

/**
 * Gently scroll to the top of the page
 *
 * @returns {void}
 *
 * ```ts
 * toTop()
 * ```
 */
const toTop = () => {
  try {
    window.scroll(0, 0)
  } catch (error) {
    console.error(error)
  }
}

/**
 * Set page header data
 *
 * @param {string} title - Tab title
 * @param {string} description - Meta description (SEO)
 * @param {string} [thumbnail] - Page thumbnail
 * @returns {void}
 *
 * ```ts
 * setPageData(product.name, product.description, true, product.featureImage)
 * ```
 */
const setPageData = (
  title: string,
  description: string,
  thumbnail?: string,
) => {
  const titleElement: HTMLTitleElement =
    document.getElementsByTagName('title')[0]
  const descriptionElement: HTMLMetaElement = document.querySelector(
    'meta[name=description]',
  )
  const thumbnailElement: HTMLMetaElement = document.querySelector(
    'meta[name=thumbnail]',
  )

  titleElement.textContent = title
  descriptionElement.content = description

  if (thumbnail) {
    thumbnailElement.content = thumbnail
  }
}

export { scrollElementIntoView, toTop, setPageData }
