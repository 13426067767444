/* eslint-disable camelcase */
import React, { useCallback, useContext, useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router'
import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { log } from '../../../_utilities/logging'
import { RawTicket } from '../home/types'
import { FormContainer } from './styled'
import { UpdateFailureReasonPageParams, LocationState } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * Update Failure Reason Page
 * @returns {JSX.Element}
 */
const UpdateFailureReason = (): JSX.Element => {
  const { plantId, ticketId } = useParams<UpdateFailureReasonPageParams>()
  const { search } = useLocation() // Extract query parameters from URL
  const userSelector = useAppSelector(userAppSelector)
  const { showNotification } = useContext(NotificationContext)
  const [failureReason, setFailureReason] = useState<string>('')
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  const [reasonDropdownData, setReasonDropdownData] = useState<DropdownData[]>(
    [],
  )
  const [currentStatus, setCurrentStatus] = useState<string>('')

  const [statusValue, setStatusValue] = useState<string>('')

  const location = useLocation()
  const { closeReasonId } = location.state as LocationState // Cast location.state to LocationState

  console.info('REASON ID : ', closeReasonId)

  interface DropdownOption {
    displayValue: string
    id: string // Use string since we're converting ids to strings
  }

  useEffect(() => {
    // Extract status from query parameters
    const queryParams = new URLSearchParams(search)
    const statusFromUrl = queryParams.get('status')
    if (statusFromUrl) {
      setStatusValue(statusFromUrl)
    }
  }, [search])

  /**
   * Event handler for when the save button is clicked
   */
  const handleSaveFailureReason = useCallback(() => {
    if (currentStatus === '') {
      showNotification({
        title: 'Must select a failure reason',
        dismissAfter: 1500,
        type: 'error',
      })

      return null
    }

    customFetch(
      'POST',
      `tickets/reasons/${plantId}`,
      {
        // status: statusValue,
        ticket_id: ticketId,
        user: userSelector.email,
        reason_id: currentStatus,
      },
      'application/json',
      false,
      false,
    )
      .then(response => {
        if (response.code === 200) {
          showNotification({
            title: 'Failure Reason Updated',
            dismissAfter: 1500,
            type: 'success',
          })
        } else {
          log(
            '7ARGDT5A3YM2AN86XT7T',
            'Update Failure Reason.tsx',
            'handleSaveFailureReason',
            'error',
            'Failure Reason Update Failed',
            {
              response,
              ticketId,
              email: userSelector.email,
              currentStatus,
            },
          )

          showNotification({
            title: 'Failure Reason Update Failed',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .catch((error: Error) => {
        log(
          'ICVIGJCZKKMWRMI99TCJ',
          'Update Failure Reason.tsx',
          'handleSaveFailureReason',
          'error',
          'Failure Reason Update Failed',
          {
            error,
            ticketId,
            email: userSelector.email,
            currentStatus,
          },
        )

        showNotification({
          title: 'Failure Reason Update Failed',
          dismissAfter: 1500,
          type: 'error',
        })
      })
  }, [
    ticketId,
    userSelector.email,
    statusValue,
    showNotification,
    currentStatus,
  ])

  /**
   * Event handler for when the page is ready
   */
  const handlePageReady = useCallback(() => {
    setIsApiBusy(true) // Start the loading spinner

    customFetch(
      'GET',
      `tickets/reasons/${plantId}`,
      null,
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          // Process the response data
          // const dropdownOptions =  result.data.map((item: { p_value: string, id: number }) => ({
          //   displayValue: item.p_value,
          //   // id: item.id,
          //   id: item.id.toString(), // Convert ID to string
          // }));

          const dropdownOptions: DropdownOption[] = result.data.map(
            (item: { p_value: string; id: number }) => ({
              displayValue: item.p_value,
              id: item.id.toString(), // Convert ID to string
            }),
          )

          // Set the dropdown data
          setReasonDropdownData(dropdownOptions)

          // Find the reason with the matching ID
          const matchingReason = dropdownOptions.find(
            (option: DropdownOption) => option.id === closeReasonId?.toString(),
          )

          // Set the current status to the matched reason or to the first item if no match is found
          setCurrentStatus(
            matchingReason ? matchingReason.id : dropdownOptions[0]?.id || '',
          )

          // // Set the initial value if needed (e.g., select the first item)
          // if (dropdownOptions.length > 0) {
          //   setCurrentStatus(dropdownOptions[0].id);

          // }

          // Stop the loading spinner
          setIsApiBusy(false)
        } else {
          log(
            'CNMMGHGMXY84FVEN6J1O',
            'UpdateFailureReason.tsx',
            'handlePageReady',
            'error',
            'Error loading ticket data',
            { result, plantId, ticketId },
          )
          setPageError('Error loading ticket data')
          setIsApiBusy(false) // Stop the loading spinner on error
        }
      })
      .catch((error: Error) => {
        log(
          'OD9LEL9DEHUH66HPEN1Y',
          'UpdateFailureReason.tsx',
          'handlePageReady',
          'error',
          'Error loading ticket data',
          { error, plantId, ticketId },
        )
        setPageError('Error loading ticket data')
        setIsApiBusy(false) // Stop the loading spinner on error
      })
  }, [plantId, ticketId])

  return (
    <PageContainer
      height="100%"
      allowNotifications={true}
      allowUnauthenticated={false}
      navbarPosition="top"
      menuType="ticket"
      onlyShowBackButton={true}
      pageTitle="Failure Reason"
      pageError={pageError}
      onPageReady={() => handlePageReady()}
      showNavBar={true}>
      {isApiBusy ? (
        <Loading type="large" alignment="center" />
      ) : (
        <FormContainer>
          <DropdownInput
            label="Failure Reasons"
            data={reasonDropdownData}
            unselectedDisplayValue="Select a reason"
            initialValue={currentStatus}
            onChange={value => setCurrentStatus(value)}
          />
          <Spacer direction="vertical" amount="50px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Save"
              theme="main"
              size="large"
              callback={() => handleSaveFailureReason()}
            />
          </AlignmentContainer>
          {/* {console.info('selected reason: ', currentStatus)} */}
          {/* {console.info('reason data: ', reasonDropdownData)} */}
        </FormContainer>
      )}
    </PageContainer>
  )
}

export default UpdateFailureReason
