import styled from 'styled-components'
import { font } from '../../_globals/theme'

const InfoListContainer = styled.div`
  margin: 10px auto;
  max-width: ${props => props.theme.maxWidth};
`

const InfoListElement = styled.ul`
  list-style: none;
`

const InfoListElementItem = styled.li`
  padding: 2px 0;
`

const InfoListElementItemTitle = styled.span`
  font-family: ${font().bold};
`

export {
  InfoListContainer,
  InfoListElement,
  InfoListElementItem,
  InfoListElementItemTitle,
}
