import styled from 'styled-components'

const DateContainer = styled.div`
  margin-bottom: 50px;

  & > div {
    display: block;
    width: 300px;
    margin: auto;
  }
`

const ChartContainer = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 800px;
  height: ${props => props.theme.height};
`
ChartContainer.defaultProps = {
  theme: {
    height: '100%',
  },
}

export { DateContainer, ChartContainer }
