import React, { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import DropdownInput from '../../../components/dropdown-input/DropdownInput'
import { DropdownData } from '../../../components/dropdown-input/types'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import { customFetch } from '../../../_data/api'
import { log } from '../../../_utilities/logging'
import { formatData } from '../home/helpers'
import { RawTicket, Ticket } from '../home/types'
import { getDropdownData } from './helpers'
import { FormContainer } from './styled'
import { TicketFilterProps } from './types'

/**
 * Ticket Filter Page
 * @returns {JSX.Element}
 *
 */
const TicketFilter = (): JSX.Element => {
  const { id } = useParams<TicketFilterProps>() // plant ID
  const navigation = useNavigate()
  const [urlParameters] = useSearchParams()
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)
  const [pageError, setPageError] = useState<string>('')
  const [assigneeDropdownData, setAssigneeDropdownData] = useState<
    DropdownData[]
  >([])
  const [meterTypeDropdownData, setMeterTypeDropdownData] = useState<
    DropdownData[]
  >([])
  const [statusDropdownData, setStatusDropdownData] = useState<DropdownData[]>(
    [],
  )
  const [issueTypeDropdownData, setIssueTypeDropdownData] = useState<
    DropdownData[]
  >([])
  const [selectedAssignee, setSelectedAssignee] = useState<string>('all')
  const [selectedMeterType, setSelectedMeterType] = useState<string>('all')
  const [selectedStatus, setSelectedStatus] = useState<string>('all')
  const [selectedIssueType, setSelectedIssueType] = useState<string>('all')

  /**
   * Event handler for when the page is ready
   */
  /**
   * Fetch data from the API
   * @param {number} plantId Plant ID
   */
  const handlePageReady = useCallback(() => {
    const startDate = new Date()
    startDate.setHours(0, 0, 0, 1)
    const endDate = new Date()
    endDate.setHours(23, 55, 59, 0)
    const url = `tickets`
    customFetch(
      'POST',
      'tickets',
      {
        plant_id: id,
        start_date: Math.round(startDate.getTime() / 1000).toString(),
        end_date: Math.round(endDate.getTime() / 1000).toString(),
      },
      'application/json',
      false,
      false,
    )
      .then(result => {
        console.info('PLANT ID FILTER', id) // 652
        if (result.code === 200) {
          const rawTickets: RawTicket[] = result.data.anomalies
          const formattedTickets: Ticket[] = formatData(rawTickets)

          setAssigneeDropdownData([
            { id: 'all', displayValue: 'All' },
            ...getDropdownData(formattedTickets, 'assignee'),
          ])

          setMeterTypeDropdownData([
            { id: 'all', displayValue: 'All' },
            ...getDropdownData(formattedTickets, 'meterType'),
          ])

          setStatusDropdownData([
            { id: 'all', displayValue: 'All' },
            ...getDropdownData(formattedTickets, 'status'),
          ])

          setIssueTypeDropdownData([
            { id: 'all', displayValue: 'All' },
            ...getDropdownData(formattedTickets, 'issueType'),
          ])

          if (urlParameters.get('assignee')) {
            setSelectedAssignee(urlParameters.get('assignee') || 'all')
          }

          if (urlParameters.get('meterType')) {
            setSelectedMeterType(urlParameters.get('meterType') || 'all')
          }

          if (urlParameters.get('status')) {
            setSelectedStatus(urlParameters.get('status') || 'all')
          }

          if (urlParameters.get('issueType')) {
            setSelectedIssueType(urlParameters.get('issueType') || 'all')
          }
        } else {
          log(
            'AN9YG2HOEHYZ9BFJQCS7',
            'Filter.tsx',
            'handlePageReady',
            'error',
            'Error Loading Portfolio Data',
            { result, url },
          )
          setPageError('Error Loading Portfolio Data')
        }
      })
      .catch((error: Error) => {
        log(
          '27LCS3PRGUX3H793L4YR',
          'Filter.tsx',
          'handlePageReady',
          'error',
          'Error Loading Portfolio Data',
          { error, url },
        )
        setPageError('Error Loading Portfolio Data')
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [id, urlParameters])

  /**
   * Event handler for when the apply filters button is clicked
   */
  const handleApplyFilters = useCallback(() => {
    let url = '/tickets'
    url += `?plantId=${id}` // Include plantId in the URL
    url += `&assignee=${selectedAssignee}`
    url += `&meterType=${selectedMeterType}`
    url += `&status=${selectedStatus}`
    url += `&issueType=${selectedIssueType}`

    navigation(url)
  }, [
    navigation,
    id,
    selectedAssignee,
    selectedIssueType,
    selectedMeterType,
    selectedStatus,
  ])

  return (
    <PageContainer
      height="100%"
      allowNotifications={true}
      allowUnauthenticated={false}
      pageError={pageError}
      navbarPosition="top"
      menuType="ticket"
      onlyShowBackButton={true}
      pageTitle="Filter Tickets"
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      <Spacer direction="vertical" amount="30px" display="block" />
      {isApiBusy ? (
        <Loading type="large" />
      ) : (
        <FormContainer>
          <DropdownInput
            label="Assigned To"
            display="block"
            data={assigneeDropdownData}
            initialValue={selectedAssignee}
            onChange={newValue => setSelectedAssignee(newValue)}
          />
          <Spacer direction="vertical" amount="15px" display="block" />
          <DropdownInput
            label="Meter Type"
            display="block"
            data={meterTypeDropdownData}
            initialValue={selectedMeterType}
            onChange={newValue => setSelectedMeterType(newValue)}
          />
          <Spacer direction="vertical" amount="15px" display="block" />
          <DropdownInput
            label="Status"
            display="block"
            data={statusDropdownData}
            initialValue={selectedStatus}
            onChange={newValue => setSelectedStatus(newValue)}
          />
          <Spacer direction="vertical" amount="15px" display="block" />
          <DropdownInput
            label="Issue Type"
            display="block"
            data={issueTypeDropdownData}
            initialValue={selectedIssueType}
            onChange={newValue => setSelectedIssueType(newValue)}
          />
          <Spacer direction="vertical" amount="50px" display="block" />
          <AlignmentContainer align="center" display="block">
            <Button
              text="Apply"
              theme="main"
              size="large"
              callback={() => handleApplyFilters()}
            />
          </AlignmentContainer>
        </FormContainer>
      )}
    </PageContainer>
  )
}

export default TicketFilter
