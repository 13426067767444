import React, { useMemo } from 'react'
import { DefaultTheme } from 'styled-components'

import Spacer from '../spacer/Spacer'
import TextElement from '../text/Text'
import {
  InfoListContainer,
  InfoListElement,
  InfoListElementItem,
  InfoListElementItemTitle,
} from './styled'
import { InfoListProps } from './types'

const InfoList = ({ title, data, maxWidth }: InfoListProps): JSX.Element => {
  const containerTheme = useMemo<DefaultTheme>(() => {
    const theme: DefaultTheme = {
      maxWidth: maxWidth ?? 'initial',
    }

    return theme
  }, [maxWidth])

  return (
    <InfoListContainer theme={containerTheme}>
      <TextElement text={title} theme="h3" alignment="center" display="block" />
      <Spacer direction="vertical" amount="10px" display="block" />
      <InfoListElement>
        {data.map(item => (
          <InfoListElementItem key={item.title}>
            <InfoListElementItemTitle>{`${item.title}: `}</InfoListElementItemTitle>
            {item.data}
          </InfoListElementItem>
        ))}
      </InfoListElement>
    </InfoListContainer>
  )
}

export default InfoList
