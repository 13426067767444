/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import AlignmentContainer from '../../../components/alignment-container/AlignmentContainer'
import Button from '../../../components/button/Button'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import Spacer from '../../../components/spacer/Spacer'
import TextInput from '../../../components/text-input/TextInput'
import TextElement from '../../../components/text/Text'
import { customFetch } from '../../../_data/api'
import { useAppSelector } from '../../../_globals/hooks'
import { NotificationContext } from '../../../_globals/notifications/notification-context'
import { RootState } from '../../../_globals/state-store'
import { log } from '../../../_utilities/logging'
import { formatData, savePossiblePlants } from './helpers'
import {
  CardContainer,
  CardControls,
  CardHeader,
  CardInfo,
  CardRow,
  ListContainer,
} from './styled'
import { PlantCard } from './types'

const userAppSelector = (state: RootState) => state.user

/**
 * Plants Home Page
 * @returns {JSX.Element}
 */
const PlantsHome = (): JSX.Element => {
  const { showNotification } = useContext(NotificationContext)
  const userSelector = useAppSelector(userAppSelector)
  const navigation = useNavigate()
  const [data, setData] = useState<PlantCard[]>([])
  const [pageError, setPageError] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(true)

  /**
   * Filtered Data based on search query input
   */
  const filteredData = useMemo(
    () =>
      searchQuery === ''
        ? data
        : data.filter(plant =>
            plant.plantName.toLowerCase().includes(searchQuery.toLowerCase()),
          ),
    [data, searchQuery],
  )

  /**
   * Event handler for when the page is ready
   */
  const handlePageReady = useCallback(() => {
    setIsApiBusy(true)
    const startDate = new Date()
    startDate.setHours(0, 0, 0, 1)
    const endDate = new Date()
    endDate.setHours(23, 55, 59, 0)

    customFetch(
      'POST',
      'summary/dashboard',
      {
        plant_id_list: userSelector.plants.map(plant => plant.id),
        start_date: Math.round(startDate.getTime() / 1000).toString(),
        end_date: Math.round(endDate.getTime() / 1000).toString(),
      },
      'application/json',
      false,
      false,
    )
      .then(result => {
        if (result.code === 200) {
          const formattedData: PlantCard[] = formatData(
            result.data.data, // Assuming this is correct based on response example
            userSelector.plants,
          )

          setData(formattedData)
          console.info('FORMATTED DATA: ',formattedData)
          
        } else {
          log(
            'ZA2AHBQM5G1HHG11OHWT',
            'PlantsHome.tsx',
            'handlePageReady',
            'error',
            'Error Loading Portfolio Data',
            result,
          )
          setPageError('Error Loading Portfolio Data')

          showNotification({
            title: 'Error Loading Portfolio Data',
            dismissAfter: 1500,
            type: 'error',
          })
        }
      })
      .finally(() => {
        setIsApiBusy(false)
      })
  }, [showNotification, userSelector.plants])

  /**
   * Event handler for when the user clicks the details button
   */
  const handleShowDetails = useCallback(
    (plantId: string) => {
      console.info("userSelector.plants", userSelector.plants)
      const plant = userSelector.plants.find(
        plantData => plantData.id.toString() === plantId,
      )


      if (!plant) {
        console.error(`Plant with ID ${plantId} not found in userSelector.plants`)
        return
      }

      savePossiblePlants(data, plantId)

      navigation(`/plant/${plant.id}/details`)
    },
    [data, navigation, userSelector.plants],
  )
  // const handleShowDetails = useCallback(
  //   (plantId: string) => {
  //     console.info("userSelector.plants", userSelector.plants);
  //     const plant = userSelector.plants.find(
  //       plantData => plantData.id.toString() === plantId
  //     );
  
  //     if (!plant) {
  //       console.error(`Plant with ID ${plantId} not found in userSelector.plants`);
  //       return;
  //     }
  
  //     savePossiblePlants(data, plantId);
  
  //     // navigation(`/plant/${plant.id}/details`);
  //     navigation(`/plant/${plantId}/details`);
  //   },
  //   [data, navigation, userSelector.plants]
  // );
  
  

  /**
   * Event handler for when the user clicks the view plant button
   */
  const handleViewPlant = useCallback(
    (plantId: string) => {
      const plant = userSelector.plants.find(
        plantData => plantData.id.toString() === plantId,
      )

      savePossiblePlants(data, plantId)

      // navigation(`/plant/${plant.id}-${plant.name}/overview`)
      navigation(`/plant/${plant.id}/overview`)
    },
    [data, navigation, userSelector.plants],
  )

  

  return (
    <PageContainer
      height="100%"
      offsetTop="20px"
      offsetBottom="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      pageError={pageError}
      navbarPosition="bottom"
      menuType="main"
      pageTitle="Portfolio Home"
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      <AlignmentContainer align="center" display="block">
        <TextInput
          display="inline-block"
          placeholder="Search for a plant"
          width="100%"
          onTextChange={newValue => setSearchQuery(newValue)}
        />
      </AlignmentContainer>
      {filteredData.length === 0 ? (
        <>
          <Spacer direction="vertical" amount="20px" display="block" />
          {isApiBusy ? (
            <Loading type="large" />
          ) : (
            <TextElement
              text="No plants found"
              theme="paragraph"
              display="block"
              alignment="center"
            />
          )}
        </>
      ) : (
        <ListContainer>
          {filteredData.map(plant => (
            <CardContainer key={plant.plantName} className={plant.colour}>
              <CardHeader>{plant.plantName}</CardHeader>
              <CardRow>
                <CardInfo className="header">Actual Energy</CardInfo>
                <CardInfo className="data">{plant.actualEnergy}</CardInfo>
              </CardRow>
              <CardRow>
                <CardInfo className="header">Capacity</CardInfo>
                <CardInfo className="data">{plant.capacity}</CardInfo>
              </CardRow>
              <CardRow>
                <CardInfo className="header">GHI/GII</CardInfo>
                <CardInfo className="data">{plant.ghiGii}</CardInfo>
              </CardRow>
              <CardRow>
                <CardInfo className="header">PR</CardInfo>
                <CardInfo className="data">{plant.pr}</CardInfo>
              </CardRow>
              <CardControls>
                {console.info("plant id in /plant page", filteredData)}
                <Button
                  text="Details"
                  display="inline-block"
                  theme="dark"
                  callback={() => handleShowDetails(plant.id)}
                  // callback={() => handleShowDetails('652')}
                />
                <Spacer
                  direction="horizontal"
                  amount="20px"
                  display="inline-block"
                />
                <Button
                  text="View"
                  display="inline-block"
                  theme="dark"
                  callback={() => handleViewPlant(plant.id)}
                />
              </CardControls>
            </CardContainer>
          ))}
        </ListContainer>
      )}
    </PageContainer>
  )
}

export default PlantsHome
