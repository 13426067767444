/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
import { useParams } from 'react-router-dom'

import DateInput from '../../../components/date-input/DateInput'
import Loading from '../../../components/loading/Loading'
import PageContainer from '../../../components/page-container/PageContainer'
import { customFetch } from '../../../_data/api'
import { colours } from '../../../_globals/theme'
import { getEnvironmentSettings } from '../../../_utilities/config'
import { log } from '../../../_utilities/logging'
import { PlantIrradiancePageParams } from './types'
import { ChartContainer, DateContainer } from './styled'
import { createChartOptions, getChartHeight, getChartWidth } from './helpers'

const environment = getEnvironmentSettings()
let refreshInterval: NodeJS.Timeout
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
)

/**
 * Plant Irradiance Page
 * @returns {JSX.Element}
 */
const PlantIrradiance = (): JSX.Element => {
  const { id } = useParams<PlantIrradiancePageParams>()
  const [pageError, setPageError] = useState<string>('')
  const [isApiBusy, setIsApiBusy] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [labels, setLabels] = useState<string[]>([])
  const [powerSeries, setPowerSeries] = useState<number[]>([])
  const [irrSeries, setIrrSeries] = useState<number[]>([])
  const [prSeries, setPrSeries] = useState<number[]>([])
  const [chartHeight, setChartHeight] = useState<string>(getChartHeight())
  const [chartWidth, setChartWidth] = useState<string>(getChartWidth())

  /**
   * The plant id, based on the url parameter
   */
  const plantId = useMemo(() => Number.parseInt(id.split('-')[0], 10), [id])

  /**
   * The plant name, based on the url parameter
   */
  const plantName = useMemo(() => id.split('-')[1], [id])

  /**
   * Flag to indicate if the data has loaded
   */
  const hasDataLoaded = useMemo(
    () => powerSeries.length > 0 && irrSeries.length > 0 && prSeries.length > 0,
    [irrSeries.length, powerSeries.length, prSeries.length],
  )

  /**
   * chart.js options
   */
  const chartOptions = useMemo(
    () => createChartOptions(plantName, selectedDate),
    [plantName, selectedDate],
  )

  /**
   * Fetch all relevant data for the page
   *
   * @param {Date} newDate The date to fetch data for
   */
  const fetchData = useCallback(
    (newDate?: Date) => {
      const startDate = new Date(newDate || Date.now())
      startDate.setHours(0, 0, 0, 1)
      const endDate = new Date(newDate || Date.now())
      endDate.setHours(23, 55, 59, 0)
      const fetchParameters = {
        meter_id: 1,
        plant_id: plantId,
        start_date: Math.round(startDate.getTime() / 1000).toString(),
        end_date: Math.round(endDate.getTime() / 1000).toString(),
        start_date_previous: Math.round(startDate.getTime() / 1000).toString(),
        end_date_previous: Math.round(endDate.getTime() / 1000).toString(),
        mode: 'best',
      }

      customFetch(
        'POST',
        'power_new',
        fetchParameters,
        'application/json',
        false,
        false,
      )
        .then(result => {
          if (result.code === 200) {
            const firstNullValue =
              result.data.plots[0].series.indexOf(null)

            setLabels(() => [...result.data.plots[0].labels.slice(0, 288)])
            setPowerSeries(() => [
              ...result.data.plots[0].series.slice(0, firstNullValue),
            ])
          } else {
            log(
              'KW5LCEGHY6EP59SPP6VN',
              'PlantIrradiance.tsx',
              'fetchData',
              'error',
              'Error fetching power data',
              result,
            )
            setPageError('Error fetching data')
          }
        })
        .catch((error: Error) => {
          log(
            'IRYJSUYHR8H0LSFNG72E',
            'PlantIrradiance.tsx',
            'fetchData',
            'error',
            'powerData error',
            error,
          )
          setPageError(error.message)
        })
        .finally(() => {
          setIsApiBusy(false)
        })

      customFetch(
        'POST',
        'irr',
        fetchParameters,
        'application/json',
        false,
        false,
      )
        .then(result => {
          if (result.code === 200) {
            setIrrSeries(() => [...result.data.plots[0].series])
            setPrSeries(() => [...result.data.plots[1].series])
          } else {
            log(
              'HENUBUMLJTLPF4G8OVA0',
              'PlantIrradiance.tsx',
              'fetchData',
              'error',
              'Error fetching irr data',
              result,
            )
            setPageError('Error fetching data')
          }
        })
        .catch((error: Error) => {
          log(
            '7MR31SG9WVOJ0MCZE45G',
            'PlantIrradiance.tsx',
            'fetchData',
            'error',
            'irrData error',
            error,
          )
          setPageError(error.message)
        })
        .finally(() => {
          setIsApiBusy(false)
        })
    },
    [plantId],
  )

  /**
   * Handle the page being ready
   */
  const handlePageReady = useCallback(() => {
    fetchData()
  }, [fetchData])

  /**
   * Handle the date being selected
   * @param {Date} newDate The new date
   */
  const handleSelectDate = useCallback(
    (newDate: Date) => {
      fetchData(newDate)
      setSelectedDate(newDate)
    },
    [fetchData],
  )

  /**
   * Handle the window being resized
   */
  const handleResize = useCallback(() => {
    setChartHeight(getChartHeight())
    setChartWidth(getChartWidth())
  }, [])

  useEffect(() => {
    // setup the window resize listener

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    // setup the data refresh interval

    clearInterval(refreshInterval)

    refreshInterval = setInterval(() => {
      fetchData()
    }, environment.dataRefreshInterval)

    return () => {
      clearInterval(refreshInterval)
    }
  }, [fetchData])

  return (
    <PageContainer
      offsetTop="20px"
      offsetBottom="20px"
      offsetMode="padding"
      allowNotifications={true}
      allowUnauthenticated={false}
      pageError={pageError}
      onlyShowBackButton={false}
      navbarPosition="top"
      menuType="plant"
      pageTitle={plantName}
      showNavBar={true}
      onPageReady={() => handlePageReady()}>
      <DateContainer>
        <DateInput
          initialValue={selectedDate}
          onChange={newValue => handleSelectDate(newValue)}
          maximumDate={new Date()}
          label="Select Date"
          width="300px"
          offsetLeft="auto"
          offsetRight="auto"
          offsetMode="margin"
        />
      </DateContainer>
      {isApiBusy || hasDataLoaded === false ? (
        <Loading type="large" alignment="center" />
      ) : (
        <ChartContainer theme={{ height: chartHeight }}>
          <Line
            data={{
              labels,
              datasets: [
                {
                  label: 'Outgoing ABT',
                  data: powerSeries,
                  borderColor: colours.green,
                  backgroundColor: colours.green,
                  yAxisID: 'yLeft',
                },
                {
                  label: 'Hz IRR',
                  data: irrSeries,
                  borderColor: colours.red,
                  backgroundColor: colours.red,
                  yAxisID: 'yRight',
                },
                {
                  label: 'Tilt IRR',
                  data: prSeries,
                  borderColor: colours.indigo,
                  backgroundColor: colours.indigo,
                  yAxisID: 'yRight',
                },
              ],
            }}
            height={chartHeight}
            width={chartWidth}
            options={chartOptions}
          />
        </ChartContainer>
      )}
    </PageContainer>
  )
}

export default PlantIrradiance
