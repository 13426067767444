import styled from 'styled-components'
import { colours } from '../../../_globals/theme'

const DateContainer = styled.div`
  margin-bottom: 50px;

  & > div {
    display: block;
    max-width: 300px;
    margin: auto;
  }
`

const SummaryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 800px) {
    max-width: 590px;
    margin-left: auto;
    margin-right: auto;
  }
`

const SummaryCard = styled.div`
  flex: 1;
  margin: 10px;
  min-width: 150px;
  background-color: white;
  padding: 15px;
`

const SummaryTitle = styled.h2`
  color: ${props => props.theme.colour};
  font-size: 100%;
  height: 50px;
  text-align: center;
`
SummaryTitle.defaultProps = {
  theme: {
    colour: 'black',
  },
}

const DeviceContainer = styled.div`
  margin: 20px;
  text-align: center;
`

const DeviceLabel = styled.p`
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
`

const DeviceBlock = styled.div`
  width: 75px;
  height: 75px;
  display: inline-block;
  margin: 10px;
  padding: 10px;
  cursor: pointer;

  & ${DeviceLabel} {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:focus ${DeviceLabel} {
    opacity: 1;
  }

  &.communicating {
    background-color: ${colours.green};
    color: white;
  }

  &.communication-error {
    background-color: ${colours.yellow};
    color: black;
  }

  &.device-breakdown {
    background-color: ${colours.red};
    color: white;
  }

  &.spare {
    background-color: ${colours.grey};
    color: white;
  }
`

export {
  DateContainer,
  SummaryContainer,
  SummaryCard,
  DeviceContainer,
  DeviceBlock,
  DeviceLabel,
  SummaryTitle,
}
