import XDate from 'xdate'

/**
 * Get the height of the chart based on the window height
 * @returns {string} The height of the chart
 *
 * @example
 * ```ts
 * const chartHeight = getChartHeight()
 * ```
 */
const getChartHeight = () => {
  const height = window.innerHeight

  if (height < 700) {
    return '500px'
  }

  return `${window.innerHeight - 275}px`
}

/**
 * Get the width of the chart based on the window width
 * @returns {string} The width of the chart
 *
 * @example
 * ```ts
 * const chartWidth = getChartWidth()
 * ```
 */
const getChartWidth = () => {
  const width = window.innerWidth

  if (width > 800) {
    return '800px'
  }

  return `${width}px`
}

/**
 * Create the chart.js options
 *
 * @param {string} plantName The name of the plant
 * @param {Date} selectedDate The selected date
 * @returns {ChartOptions} The chart.js options
 *
 * @example
 * ```ts
 * const chartOptions = createChartOptions(plantName, selectedDate)
 * ```
 */
const createChartOptions = (plantName: string, selectedDate: Date) => {
  const formattedDate = new XDate(selectedDate).toString('dddd, MMMM dS, yyyy')
  const title = `${plantName} - ${formattedDate}`

  return {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: true,
    },
    plugins: {
      title: {
        display: true,
        text: title,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          pinch: {
            enabled: true,
          },
          wheel: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
    scales: {
      yLeft: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          text: 'MW',
          display: true,
        },
      },
      yRight: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          text: 'W/m2',
          display: true,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any
}

export { getChartHeight, getChartWidth, createChartOptions }
